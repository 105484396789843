class NewSocialPage {
  static init() {
    if (!document.querySelector('main.new-social-page')) {
      return;
    }

    document
      .querySelector('#hero-video-player-wrapper')
      ?.addEventListener('click', this.playVideoInFullScreen);

    document.addEventListener('fullscreenchange', () => {
      if (!document.fullscreenElement) {
        NewSocialPage.pauseVideo();
      }
    });
  }

  static getPlayer() {
    /* vimeo player sdk is not available */
    if (typeof window.Vimeo === 'undefined' || !window.Vimeo.Player) {
      return;
    }

    const videoPlayer = new window.Vimeo.Player(
      document.querySelector('#video iframe')
    );

    if (!videoPlayer) {
      return;
    }

    return videoPlayer;
  }

  static playVideoInFullScreen() {
    const videoPlayer = NewSocialPage.getPlayer();

    if (!videoPlayer) {
      return;
    }

    videoPlayer.requestFullscreen();
    videoPlayer.play();
  }

  static pauseVideo() {
    const videoPlayer = NewSocialPage.getPlayer();

    if (!videoPlayer) {
      return;
    }

    videoPlayer.pause();
  }
}

export default NewSocialPage;
