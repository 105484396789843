module.exports = {
  btnChat: {
    chatWithTobias: 'Chatte mit Tobias',
    contactWojtek: 'Kontaktiere Wojtek'
  },
  careerPage: {
    contactBox: {
      title: 'Wir freuen uns auf deinen Anruf.'
    },
    mainTeaserOne: {
      copyOne:
        'Du willst richtig was bewegen und Teil eines außergewöhnlichen Teams werden? Dann bist du bei uns genau richtig. Denn wir bei Lingner sind nicht nur gelb, sondern vor allem bunt. Mach mit uns gemeinsame Sache und alles außer gewöhnlich.',
      title: 'Team Gelb.'
    },
    mainTeaserTwo: {
      copyOne:
        'kann jeder, aber mit dem Standard gibst du dich nicht zufrieden? Gut. Wir auch nicht. Deshalb suchen wir nach außergewöhnlichen Talenten, kreativen Köpfen und vielseitigen Profis wie dir.',
      copyTwo:
        'Uns geht es allerdings nicht nur um deine Hard- oder Softskills, deinen CV oder wieviele Awards du in der Tasche hast. Viel wichtiger ist für uns die richtige Attitude. Deine Leidenschaft, dein Teamgeist, die Freude am Lernen, der Wille etwas zu bewegen, Spaß daran komplexe B2B Themen spielend leicht wirken zu lassen und eine angeborene unstillbare Neugier. Siehst du deine Stärken eher im Programmieren, Layouten, Spots schreiben, Konzipieren, Animieren, Organisieren, Präsentieren, Beraten, strategisch denken oder gehörst du zu denen, die in keine Schublade passen? Ganz egal. Das finden wir nämlich alles cool und sicher auch den dazu passenden Platz bei uns.',
      cta: 'Jetzt bewerben',
      title: 'Irgendwas mit Medien...'
    },
    mainTeaserThree: {
      copyOne:
        'Klingt nach medizinischem Notfall, ist aber in diesem Fall lediglich eine  Übersicht unserer zu besetzenden Positionen.',
      title: 'Offene Stellen.'
    },
    mainTeaserFour: {
      slider: {
        slide0: {
          aside: {
            copyOne:
              'Ja, schon klar. 13 gute Gründe hätte irgendwie epischer geklungen. Aber es sind dann am Ende doch mehr als 13 geworden. Wir lassen das jetzt so.',
            title: '14 gute Gründe.'
          }
        },
        slide1: {
          aside: {
            copyOne:
              'Persönlicher Freiraum und Respekt werden bei uns groß, bold und versal geschrieben. Egomanen und kreative Endgegner hingegen bleiben bitte zuhause oder in den 90ern. Wer unnötige Allüren ablegt, den erwarten bei uns außergewöhnliche Kolleginnen aus der ganzen Welt. Manchmal fühlen wir uns wie eine kleine gelbe Familie. Deshalb ist Nachwuchs für uns auch ganz normal, und wir wissen, wie man diesen in den Arbeitsalltag einbauen kann oder auch andersrum.',
            title: '#1 Werde lingner.com.'
          }
        },
        slide2: {
          aside: {
            copyOne:
              'Wir sind stolz in einem der schönsten und traditionsreichsten Bauwerke Heilbronns arbeiten zu dürfen. Das ist die klassizistische Villa Mertz. Direkt an der Neckarpromenade gelegen, verfügt sie über einen eigenen Park, den wir im Sommer nicht nur zum Grillen sondern auch zum kreativ Luft holen nutzen. In direkter Nachbarschaft befindet sich außerdem der Bahnhof, ein Freibad, die Fußgängerzone und die Heilbronner Gastromeile.',
            title: '#2 Ja, ich Villa.'
          }
        },
        slide3: {
          aside: {
            copyOne:
              'Unsere B2B-Kunden eint: Ihre Produkte sind erklärungsbedürftig und treffen auf ein anspruchsvolles und internationales Fachpublikum. Das lässt unser Herz höherschlagen, denn wir lieben es, komplizierte Themen in faszinierend zugängliche Kommunikation zu verwandeln. Zum Beispiel machen wir den Biotech-Konzern Qiagen Social-Media-affin, konzipieren und implementieren Enterprise CMS (Software AG), entwickeln und betreiben Internet- und Intranet-Applikationen für Implenia, Livestreams für die Corona-Politik der Bundesrepublik oder entwickeln eine Arbeitgebermarke für Leadec.',
            title: '#3 Kompliziert.'
          }
        },
        slide4: {
          aside: {
            copyOne:
              'So unterschiedlich wie unsere Kunden, so spannend sind auch die Herausforderungen, die auf dich warten. Neues erfordert aber nicht nur den Mut des Kunden, sondern auch das Wissen und die Motivation aller Kolleginnen (m/w/d). Darum arbeiten wir in interdisziplinären Teams und haben keine Angst vor Neuem. Wir entwickeln uns ständig weiter und bleiben uns trotzdem treu. Wie Pikachu. Du hast eine besondere Stärke oder Fähigkeit und noch viel mehr Potenzial? Perfekt, dann lass uns gemeinsam daran arbeiten, dich noch besser zu machen.',
            title: '#4 Stark Machen.'
          }
        },
        slide5: {
          aside: {
            copyOne:
              'Das ist nicht immer einfach, aber für uns einfach immer wichtig. Denn ohne Vertrauen in die eigenen Fähigkeiten, Ideen, Kompetenzen und Potenziale ist es unmöglich, ausgetretene Pfade zu verlassen und Neues zu erschaffen. Wir trauen uns gerne auch mal selbstkritisch zu sein, Ideen auch offen zu hinterfragen und weiterzuentwickeln. Dieser „Mut zum Vertrauen“ ist die Basis unserer Kultur.',
            title: '#5 Mut zum Vertrauen.'
          }
        },
        slide6: {
          aside: {
            copyOne:
              'Wir sind Unternehmensberatung und Kreativagentur in einem. Das ist für uns allerdings kein Widerspruch, sondern Identität. Denn wir verbinden Unternehmensstrategie mit kreativer Leichtigkeit, Schaubild mit Markenauftritt, Tradition mit digitaler Zukunft, B2B mit LOL, Wallstreet mit Streetstyle und suchen nicht den leichtesten Weg, sondern einen, der zum Ziel führt.',
            title: '#6 Zwei Welten.'
          }
        },
        slide7: {
          aside: {
            copyOne:
              'Traditionelle Organisationsformen wurden unseren Ansprüchen nicht gerecht und deshalb haben wir uns für einen agileren und innovativeren Ansatz entschieden: die Podular Organization. Podular ist die Wortkombination aus Pod und Modular. Unsere Expertenteams (pods) aus allen kommunikativen Disziplinen einer Full-Service-Agentur kombinieren wir individuell (modular), um jede kommunikative Herausforderung unserer Kunden zu meistern. So bleiben wir kollaborativ, dynamisch und handlungsfähig, ohne dabei unnötig große Teams oder künstliche Hierarchien zu bauen. Das Wort, das sie suchen, ist pod-ular. po-du-lar.',
            title: '#7 Podular.'
          }
        },
        slide8: {
          aside: {
            copyOne:
              'Damit sich deine Bandscheiben auch im Mobile Office wohlfühlen, gibt es von uns alles, was du dazu brauchst, nach Hause geliefert. Auch der höhenverstellbare Schreibtisch, ein ergonomischer Hocker oder das Wackelbrett gehen auf unseren Nacken. Damit es deinem gut geht.',
            title: '#8 Heim-werken.'
          }
        },
        slide9: {
          aside: {
            copyOne:
              'Bei uns gibt es nicht nur den obligatorischen Obstkorb, eine italienische Siebträgermaschine, den Kicker und die Getränkeflatrate. Darüber hinaus hast du bei uns die Möglichkeit auf 30 Tage Urlaub, Handyvertrag, Fahrtkostenzuschuss, Fortbildung etc. Du suchst was Festes? Gut, wir auch und deshalb sind unbefristete Arbeitsverträge bei uns die Regel. Flexible Arbeitszeiten und Teilzeitmodelle schaffen Raum für Nachwuchs, Familie, Freunde, Hobbies oder eigene Projekte. Und auch wenn es um unseren Erfolg geht, lieben wir es transparent. Siehe #11.',
            title: '#9 Benefits.'
          }
        },
        slide10: {
          aside: {
            copyOne:
              'Raum zur Entfaltung, den brauchen wir nicht nur geistig, sondern auch physisch. Als möglicher Arbeitsplatz dient uns zu diesem Zweck nicht nur das charmante Hauptquartier mitten in der Heilbronner Innenstadt. Darüber hinaus bieten wir dir zusätzlichen Raum zur kreativen Entfaltung. Beispielsweise mit dem an die Villa angebauten und preisgekrönten WERK1, dem im Park gelegenen LINGNER.LAB und unserem WERK2 in urbaner Studio-Optik.',
            title: '#10 Freiräume.'
          }
        },
        slide11: {
          aside: {
            copyOne:
              'Nichts als Liebe empfinden wir für den zur Villa gehörenden Park. Hier verbringen wir die Mittagspause unter Kastanienbäumen, die kreative Denkpause auf dem kiesigen Rundweg und das Feierabendbier schmeckt besonders gut beim Flanieren, während die Eichhörnchen sich um den Springbrunnen versammeln. Entschleunigung beginnt bei uns direkt vor der Haus-, bzw. Villa-Tür.',
            title: '#11 Herz-Rasen.'
          }
        },
        slide12: {
          aside: {
            copyOne:
              'Der digitale LINGNER.GOLD-Kurs zeigt unseren Mitarbeitern jederzeit und ganz transparent an, wie das Unternehmen wirtschaftlich unterwegs ist. Außerdem ist er sogar in der Lage, aus den Daten der Buchhaltung Prognosen zu erstellen. Jedes einzelne, erfolgreiche Projekt wirkt sich ganz direkt positiv auf den LINGNER.GOLD-Kurs aus. Dieser wird in Euro angegeben und am Beginn des Folgejahres zusätzlich ausbezahlt. Denn wenn es dem Unternehmen gut geht, dann geben wir das weiter. An diejenigen, die das erst möglich gemacht haben: das gesamte Team. Ist bei uns so `ne goldene Regel.',
            title: '#12 Zahlen, bitte.'
          }
        },
        slide13: {
          aside: {
            copyOne:
              'Früher auch gerne mal Heil-Bronx genannt, hat die Stadt am Neckar ihr angestaubtes Image hinter sich gelassen. Dank zahlreicher Großprojekte wie der Bundesgartenschau, der experimenta oder dem Bildungscampus wächst hier nicht nur der Wein, sondern auch die Wirtschaft stark. Hier siedelten sich gleich vier Hochschulen an. Darunter findet sich beispielweise die innovative Coding School 42 und die TUM. Dadurch gehören Bildung und urbaner Flair zur Universitätsstadt Heilbronn, wie die Soß‘ zu den Spätzle. Investiert wird hier aber nicht nur in die Wirtschaft, sondern auch in die Zukunft. Beispielsweise mit kostenlosen und verfügbaren Kindergartenplätzen und dem neuen grünen Stadtteil Neckarbogen.',
            title: '#13 Heilbronn.'
          }
        },
        slide14: {
          aside: {
            copyOne:
              'Kaffee ist nun wirklich kein Grund bei uns zu arbeiten? Absolut richtig. Vielleicht aber auch absolut falsch. Denn als Kaffeeliebhaber findest du bei uns genug, deren Konfession Koffein heißt. Nicht nur die italienische Siebträgermaschine, die ausgewählten Bohnensorten, sondern auch die angeregten Diskussionen rund um deren Mischung und Zubereitung werden dein Herz höher und vor allem schneller schlagen lassen. Einige unserer Mitarbeiter zaubern mit Milch stärkere Illusionen in den Kaffee als die Ehrlich Brothers auf die Bühne.',
            title: '#14 schwarzes Gold.'
          }
        }
      },
      title: 'Und warum Lingner?'
    }
  },
  caseStudyPage: {
    customer: 'Kunde',
    service: 'Unser Service',
    tagline: 'Case',
    dentsplySironaPrimeprintVirtualProduction: {
      contactBox: {
        // eslint-disable-next-line quotes
        title: "Neugierig?<br>Let's talk."
      },
      highlights: {
        customer: 'Dentsply Sirona',
        highlightOne: 'Virtual Production'
      },
      mainHero: {
        subTitle:
          'The Magic Inside! Eine Virtual Production ermöglicht nie dagewesene Einblicke ins Innere des 3D-Druckers Primeprint'
      },
      outlinks: {
        linkTwo: {
          linkText: 'Dentsply Sirona Primemill Product Launch',
          linkUrl: '/dentsply-sirona-primemill-product-launch/',
          title: 'Nächster Fall'
        },
        title: 'Das könnte dich auch interessieren'
      },
      sectionOne: {
        copyOne:
          'Ein neues 3D-Drucksystem auf dem Dentalmarkt zu etablieren ist das eine. Die Kommunikation so zu gestalten, dass Zahnärzten und Zahntechnikern die neue Technologie nicht nur nähergebracht wird, sondern sie auch restlos davon begeistert sind, ist das andere.',
        copyTwo:
          '„Wir sind mit der Herausforderung konfrontiert, dass wir so viel Brain in das gesamte Produkt gesteckt haben, dass wir manchmal nicht genau wissen wie wir es eigentlich erklären können“, fasst es Johannes Krug zusammen, Global Head of Manufacturing Products bei Dentsply Sirona.',
        copyThree:
          'Vor allem, wenn die Magie des 3D-Druckers nahezu unsichtbar im Inneren zweier Maschinen passiert. Und genau diese Aufgaben sind es, die wir lieben.',
        titleOne: 'Herausforderung'
      },
      sectionTwo: {
        copyOne:
          'Um diesen neuartigen Druckprozess und die verschiedenen Phasen zu erläutern blieb uns lediglich eine Möglichkeit: Wir mussten die Geschichte im 3D-Drucker selbst erzählen. Und das auf eine neue Art und Weise, die Maßstäbe setzt und gleichzeitig perfekt zu diesem Hightech-Produkt passt.',
        titleOne: 'Idee'
      },
      sectionThree: {
        copyOne:
          'Im Zentrum der Kommunikation steht der Film: „The Magic Inside - Primeprint 3D Printing Solution“. Lasst euch von den charmanten Protagonisten Dr. Isabelle Quinn (Annabelle Mandeng) und Daniel Prime (Seumas Sargent) auf eine magische Reise mitnehmen, seht wie die reale mit der virtuellen Welt verschwimmt und erlebt mit ihnen die Wunderwelt der Dentaltechnik. Im folgenden Projektfilm erfahrt ihr alles über die Produktion und könnt uns am Set über die Schulter schauen.',
        copyTwo:
          'Der Konzeptionsprozess mündete bewusst in einer virtuellen Produktion, die uns größte Gestaltungsfreiheit auf der einen und Produktionssicherheit auf der anderen Seite ermöglichte.',
        copyThree:
          'Die im Vorfeld produzierten virtuellen Welten (Levels) waren z.B. die Museumsszene, das Portal sowie der digitale Zwilling des 3D-Druckers samt Funktionen und Prozessen. Im LED-Studio konnten die Protagonisten am Drehtag live in die Levels eintauchen und wurden eins mit der virtuellen Umgebung, die durch eine reale Kulisse ergänzt wurde und die Illusion perfekt machen.',
        copyFour:
          'Und das Gute am digitalen Zwilling: er kann nicht nur in diesem einen Film eingesetzt werden, sondern ist Bestandteil verschiedener Phasen der Marketing-Kommunikation.',
        titleOne: 'Kreation',
        titleTwo: 'Virtual Production und digitaler Zwilling'
      },
      sectionFour: {
        copyOne:
          'Für den Film „The Magic Inside - Primeprint 3D Printing Solution“ wurden wir mit einem silbernen Delphin in Cannes ausgezeichnet und sind erneut Preisträger des Cannes Corporate Media & TV Awards. Wir sind stolz auf die Anerkennung für ein solch besonderes Projekt.',
        copyTwo:
          'Danke an unsere Partner während der Produktion und das große, bunte und engagierte Team, die das Projekt zu diesem Erfolg geführt haben.',
        copyThree:
          'Auftraggeber: Dentsply Sirona<br>Kreation und Produktion: LINGNER.COM<br>Schauspielerin: Annabelle Mandeng<br>Schauspieler: Seumas Sargent<br>Idee / Konzept: Tobias Hurrle, Raoul Baumgärtner, Fabian Borst<br>Drehbuch: Fabian Borst, Raoul Baumgärtner, Rupert Maitland<br>Regie: Fabian Borst: Regieassistenz: Natalie Kölbl<br>Director of Photography: Chathura Panangala<br>Trinity Steadicam Operator: Janis Willbold<br>1.AC Steadicam: Marvin Puscher<br>Kamerakran Operator: Kevin Buse<br>1.AC Kamerakran: Lea Sarau<br>Focus Puller Kamerakran: Bastian Blum<br>Set-Tonmeister: Alexander Theodossiadis<br>Tontechniker: Marcus Ritzel<br>Produktionsleitung: Christian Laurin<br>Key Account: Wojtek Misiewicz<br>Bühnen- und Setbau: Tom Schilling, Robert Scherzinger, Philipp Locher<br>Oberbeleuchter: Manuel Dolderer<br>Licht Operator: Jana Radomski<br>Best Boy: Sören Gerhard<br>LEDCave Studioleitung: Thilo Strack<br>LEDCave Project Manager: Finn Krieger<br>LEDCave Technischer Leiter: Lukas Rösch<br>LEDCave Account Management: Steffen Lang<br>Set-Aufnahmeleiter: Frank Junghahn<br>Virtual Design & Unreal Specialist: Nikolaus Schlüter, Tobias Huber, Alexander Söll<br>Unreal Optimizer: Timo Müssig<br>Hair & Makeup: Charlie Ksiazek<br>Kostüm: Saskia Müller<br>Data Wrangler: Georg Solf<br>Medienoperator: Sven Schaller<br>Choreograph: Joshua Taake<br>On Set Feelgood Management: David Zimmermann<br>Storyboard & Visuals: Frank Richter, Nick Denecke<br>Fotos und Making of Video: Julian Meinhardt<br>Schnitt: Natalie Kölbl<br>Color Grading: Natalie Kölbl<br>Motion Design & Graphics: Erwin Juliestersen Kopeng<br>Filmmusik: Leonard Küßner, Musicbed<br>Sounddesign und Mischung: Frederik Pfeiffer<br>Social Media Editor: Sascha Seidel',
        titleOne: 'Auszeichnungen',
        titleTwo: 'Behind the scenes',
        titleThree: 'Credits'
      }
    },
    dentsplySironaDS24: {
      contactBox: {
        // eslint-disable-next-line quotes
        title: "Neugierig?<br>Let's talk."
      },
      highlights: {
        customer: 'Dentsply Sirona',
        highlightOne: 'Idee und Konzept',
        highlightTwo: 'Livestream Produktion',
        highlightThree: 'Interne Kommunikation'
      },
      mainHero: {
        subTitle:
          '...dann kreiert LINGNER.COM ein außergewöhnliches 24h Livestream-Experiment: #ds24hours'
      },
      outlinks: {
        linkTwo: {
          linkText: 'SharePoint Intranet der Software AG',
          linkUrl: '/software-ag-das-intranet-neu-gedacht/',
          title: 'Nächster Fall'
        },
        title: 'Das könnte dich auch interessieren'
      },
      sectionOne: {
        copyOne:
          'Die “gelebte” Kultur konnte nicht mit der Entwicklung des Transformationsprozesses Schritt halten, das Engagement der Mitarbeiter war zum damaligen Zeitpunkt laut einer Mitarbeiterumfrage zu gering. Die Herausforderung war es nun einen Weg zu finden, um dieser Entwicklung positiv entgegenzuwirken und die Kernbotschaften global und lokal im Dialog zu kommunizieren.',
        titleOne: 'Die Herausforderung.'
      },
      sectionTwo: {
        copyOne:
          'In insgesamt 24 Stunden mit wenigen Pausen innerhalb von nur 31 Stunden umgesetzt sollten in einstündigen Live-Sessions 95 Standorte erreicht werden. Im Dialog in Bild und Ton. Je nach Land oder Kontinent sollte die Liveübertragung durch Simultandolmetscher ergänzt werden.',
        slider: {
          slideOne: {
            aside: {
              copyOne:
                'Wir definierten Personas und entwickelten dann ein mehrstufiges Modell, um sowohl an die CEREC-Believers als auch an die Considerers gezielt kommunizieren zu können.'
            }
          },
          slideTwo: {
            aside: {
              copyOne: 'Echter Dialog mit den Mitarbeitern'
            }
          },
          slideThree: {
            aside: {
              copyOne: 'Live in 10 Sprachen'
            }
          },
          titleOne: 'Insights.'
        },
        titleOne: 'Strategie.'
      },
      sectionThree: {
        copyOne:
          'Für 24 Stunden live, in 24 einstündigen Town Hall Meetings, sprach das fünfköpfige Management-Team mit den Mitarbeitern. Jede der Sessions war speziell auf die jeweilige Region zugeschnitten.',
        imageCaptionOne: 'Branded Studio in der Werkshalle',
        titleOne: 'Kreation.'
      },
      sectionFour: {
        copyOne:
          'DS24hours übertraf die Erwartungen aller Beteiligten und war ein voller Erfolg. Nahezu alle Mitarbeiter weltweit wurden erreicht und sind in einen aktiven Dialog getreten, so dass nicht nur Purpose, Mission und die Unternehmenskultur stärker vermittelt, sondern auch das Engagement der Belegschaft nachhaltig gestärkt wurde.',
        copyTwo: '',
        copyThree:
          'Dentsply Sirona und LINGNER.COM wurden für das Projekt DS24hours mit zahlreichen nationalen und internationalen Awards ausgezeichnet. Wir sind stolz auf die Anerkennung für ein solch besonderes Projekt. Bei einigen Awards steht die Verleihung noch aus.',
        numberHighlightsOne: {
          itemOne: {
            number: '13.000',
            label: 'Erreichte MitarbeiterInnen'
          },
          itemTwo: {
            number: '650',
            label: 'Eingereichte Fragen'
          },
          itemThree: {
            number: '9.000',
            label: 'Aktiv beteiligt am Live-Event'
          }
        },
        titleOne: 'Das Ergebnis.',
        titleTwo: 'Auszeichnungen.'
      }
    },
    dentsplySironaPrimemillLaunch: {
      contactBox: {
        // eslint-disable-next-line quotes
        title: "Neugierig?<br>Let's talk."
      },
      highlights: {
        customer: 'Dentsply Sirona',
        highlightOne: 'Kampagnen Film',
        highlightTwo: 'Social Media Kampagne',
        highlightThree: 'Print Kampagne',
        highlightFour: 'Launch Event'
      },
      mainHero: {
        subTitle:
          '...dann orchestrieren wir den internationalen Launch nicht nur gewohnt groß und bildgewaltig, sondern auch emotional und überraschend ehrlich.'
      },
      outlinks: {
        linkTwo: {
          linkText: '24h Stream',
          linkUrl: '/dentsply-sirona-ds24/',
          title: 'Nächster Fall'
        },
        title: 'Das könnte dich auch interessieren'
      },
      sectionOne: {
        copyOne:
          'Während die User durchweg begeistert sind, bleibt eine breite Gruppe von Non-Usern skeptisch. Es kursieren Vorurteile, aber auch reale Einstiegshürden. Wie erreicht man eine Zielgruppe die sich bereits vor Jahren emotional von der Marke abgewandt hat?',
        titleOne:
          'Wie erreicht man eine Zielgruppe, die sich bereits vor Jahren emotional von der Marke abgewandt hat?'
      },
      sectionTwo: {
        copyOne:
          'Wir definierten Personas und entwickelten dann ein mehrstufiges Modell, um sowohl an die CEREC-Believers als auch an die Considerers gezielt kommunizieren zu können.',
        slider: {
          slideOne: {
            aside: {
              copyOne:
                'Wir definierten Personas und entwickelten dann ein mehrstufiges Modell, um sowohl an die CEREC-Believers als auch an die Considerers gezielt kommunizieren zu können.'
            }
          },
          titleOne: 'Insights'
        },
        titleOne:
          'Zunächst analysierten wir hunderte von Datensätzen, um Marke und Zielgruppe besser zu verstehen.'
      },
      sectionThree: {
        copyOne:
          'Im Kampagnenfilm "Now is the time" sprechen wir über die Sorgen und Ängste, die wir alle von bedeutsamen Entscheidungsmomenten in unserem Leben kennen, aber auch über das Kribbeln und die positive Energie, die mit dem Beginn eines neuen Kapitels einhergehen.',
        copyTwo:
          'Der Kampagnenfilm wird verlängert durch eine umfangreiche Social Media Kampagne, sowie Printanzeigen und weiterführendes Material für Events. Diese neue und ehrlichere Kommunikation gibt Considerern einen Anlass ihre defensive Haltung zu überdenken und sich erneut mit dem Thema CEREC zu beschäftigen.',
        copyThree:
          'Der Produktfilm "Excellence made easy", der im Mittelpunkt der Veranstaltung steht, stellt nicht nur eindrucksvoll die Features des Produktes dar, sondern strotzt nur so vor Stolz und Begeisterung: Daniel Prime\'s Vivaldi-Konzert mit CEREC Primemill als Star-Solist.',
        imageCaptionOne: '',
        imageCaptionTwo: '',
        slider: {
          slideOne: {
            copyOne: 'Don Casey, CEO at Dentsply Sirona',
            titleOne:
              '"Ein Feuerwerk, das unsere Kunden absolut umgehauen hat."'
          },
          titleOne: 'Das sagt unser Kunde'
        },
        titleOne: 'Die Ängste der considerer adressieren & überwinden',
        titleTwo: 'Pure Begeisterung beim Launch-Event'
      },
      sectionFour: {
        copyOne:
          'Eine eng verzahnte Kampagne, die von der Produkt-Enthüllung bis hin zur Social Media Kampagne den Vertrieb, die Key Opinion Leader und Kunden mitreißt, mit einer emotionalen Resonanz, wie es sie in der Dentalbranche selten gab.',
        titleOne:
          'Mehr als 3.000 verkaufte CEREC Primemill Einheiten in einem herausfordernden Jahr 2020 mit Corona-Pandemie.'
      }
    },
    weinigExperience: {
      mainHero: {
        subTitle:
          '… und von diesen hat LINGNER.COM eine Menge. So entstand für die WEINIG AG eine digitale Dauerausstellung mit maßgeschneidertem Content für Hartholz-Fans.'
      },
      contactBox: {
        // eslint-disable-next-line quotes
        title: "Neugierig?<br>Let's talk."
      },
      highlights: {
        customer: 'Weinig',
        highlightOne: 'Idee und Konzept',
        highlightTwo: 'Video-Produktion',
        highlightThree: 'Entwicklung Web-Plattform'
      },
      outlinks: {
        linkTwo: {
          linkText: '24h Stream',
          linkUrl: '/dentsply-sirona-ds24/',
          title: 'Nächster Fall'
        },
        title: 'Das könnte dich auch interessieren'
      },
      sectionOne: {
        copyOne:
          'Die Corona-Pandemie stellte die WEINIG AG vor eine echte Herausforderung. Bis zu diesem Zeitpunkt war der persönliche Kontakt zu den WEINIG-Kundinnen und Kunden eine der erfolgreichsten und wichtigsten Vertriebsinstrumente. Im Februar 2020 musste dann schnell eine andere Lösung gefunden werden. Ein neues Format, dass die Zielgruppe – genau wie eine Vor-Ort-Veranstaltung – persönlich anspricht und begeistert, gleichzeitig aber auch Mehrwerte bietet, die bei einem lokalen Event gar nicht möglich wären.',
        titleOne:
          'Die digitale Zukunft in der Holzverarbeitung: Innovative Lösungen sind gefragt'
      },
      sectionTwo: {
        copyOne:
          'Für uns war sofort klar, dass wir ein digitales Live-Event für die WEINIG AG auf die Beine stellen wollen. Unser wichtigstes Ziel für das Konzept war es, die Zielgruppe nicht nur zu erreichen, sondern sie mit unserer Kreation zu begeistern: Der Strategiemix aus digitaler Messe mit Live-Moderation, spannenden Interviews und Deep Dives und vorab aufgezeichneten Kundenstories war geboren.\n' +
          'Zusätzlich zum Live-Event sollte eine digitale Plattform entstehen, die mehr kann als nur streamen. Ein Contenthub mit weiterführenden Informationen, Live-Streams und Mediathek, soll dafür sorgen, auch nach der Messe als Verkaufs- und Leadkanal zu fungieren – Langlebigkeit stand im Fokus.',
        titleOne:
          'Eine Plattform – 3 Lösungen.<br>Live-Event, Mediathek und Informationen für After-Sales in einem Hub'
      },
      sectionThree: {
        copyOne:
          'Die Idee einer digitalen Messe verfestigte sich im Konzept “Solid Wood Summit” – ein digitales Live-Event für Hartholz-Fans! Alle Inhalte der dreitägigen Veranstaltung wurden von LINGNER.COM geplant, geschrieben und produziert. Für die Durchführung der Solid Wood Summit übernahmen wir außerdem die gesamte Organisation – vom Aufbau des Live-Studios bis hin zur Regie des Streams. \n' +
          'Auch Planung, Awareness-Maßnahmen und redaktionelle Unterstützung stammen aus der LINGNER.COM Feder – ebenso wie der Ankündigungstrailer und diverse Content-Sessions. Ein ganzes Maßnahmenpaket an Print- und digitalen Anzeigen sorgte dafür, dass wir die Zielgruppen online und offline erreichen konnten. So garantierten wir einen roten Faden von A bis Z.\n',
        copyTwo:
          'Das dreitägige Live-Event wurde auf der „WEINIG experience“ gestreamt – eine digitale Plattform auf TYPO3 Basis, die mehr kann als nur streamen. Die „WEINIG experience“ ist Streamingplattform Content-Hub und Sales Channel in einem. User konnten sich hier über Streams, Zusatzinformationen und eine Mediathek freuen und sich sogar ihren persönlichen Eventkalender zusammenstellen. Passen zu den gespeicherten Informationen wurden dem User über Solr-Schnittstellen Empfehlungen für ähnliche Vorträge ausgespielt. \n' +
          'Während des Streams gab es außerdem einen Live-Chat, der sich reger Teilnahme erfreute.\n',
        titleThree: 'Ein nachhaltiger Content-Hub als digitaler Vertriebskanal',
        copyThree:
          'Alle Beiträge der Solid Wood Summit sind bis heute auf der Plattform verfügbar. Dazu hat LINGNER.COM die Mediathek mit einer Filter- und Such-Funktion auf Solr- Basis bestückt. So sind die Inhalte für die User übersichtlicher und einfacher zugänglich. Zusätzlich zu den Videos der Solid Wood Summit findet man auf der Plattform weitere Informationsvideos, bevorstehende Veranstaltungen der WEINIG AG und Detailseiten zu einzelnen Exponaten. So ist die „WEINIG experience“ gleichzeitig ein attraktives Verkaufsinstrument, das jederzeit aktualisiert und weiterentwickelt werden kann. Durch die Login-Funktion können bestimmte Inhalte ausschließlich für eingeloggte User angezeigt werden. Dies ermöglicht beispielsweise auch den Einsatz von Rabattcodes für Messen oder das Tracking von User-Aktionen innerhalb der Plattform.\n' +
          '\n',
        titleOne: 'Eine digitale Messe mit echtem Mehrwert'
      },
      sectionFour: {
        copyOne:
          'Allein im Monat des Solid Wood Summit Streams (November 2021) hatte die „WEINIG experience“ über 3.000 monatliche Zugriffe. Über die dreitätige Solid Wood Summit erreichten wir über 2.000 Zuschauerinnen und Zuschauer.\n' +
          '\n' +
          'Die Mediathek mit allen abrufbaren Inhalten rundet das Ergebnis ab und bietet als Content-Hub auch im Nachgang des Events einen klaren Mehrwert. Auch im Jahr 2023 hat die „WEINIG experience“ noch bis zu 1.000 monatliche User, die sich dort vor allem für die WEINIG Veranstaltungen sowie die Mediathek mit den Vorträgen der Solid Wood Summit interessieren. Dies zeigt deutlich, dass das Ziel der nachhaltigen Nutzung der Plattform erfüllt wurde.\n' +
          '\n' +
          'Außerdem dient die Plattform auch heute noch als verkaufsförderndes und inspirierendes Instrument für potenzielle Einkäuferinnen und Einkäufer. Insbesondere die detaillierte Darstellung der WEINIG Exponate unterstützt dabei maßgeblich die Vertriebsmitarbeitenden der WEINIG AG.\n' +
          'Wir freuen uns die Anforderungen unseres Kunden, dank des vollen Potenzials unseres interdisziplinären Teams, nicht nur erfüllt, sondern übertroffen zu haben.',
        numberHighlightsOne: {
          itemOne: {
            number: '>2000',
            label: 'Zuschauende im Live-Stream'
          },
          itemTwo: {
            number: '>1000',
            label: 'monatliche User der WEINIG Experience auch heute noch'
          }
        },
        titleOne:
          'Nachhaltige, digitale Kommunikations- und Informationsplattform für WEINIG-Kunden'
      }
    },
    softwareAg: {
      mainHero: {
        subTitle:
          'Kommunikation, Kollaboration, Kreation – Wir feiern die Zukunft des Intranets bei der Software AG!'
      },
      highlights: {
        customer: 'Software AG',
        highlightOne: 'Social Intranet',
        highlightTwo: 'Launch Kampagne zum Intranetstart'
      },
      outlinks: {
        linkTwo: {
          linkText: '24h Stream',
          linkUrl: '/dentsply-sirona-ds24/',
          title: 'Nächster Fall'
        },
        title: 'Das könnte dich auch interessieren'
      },
      contactBox: {
        // eslint-disable-next-line quotes
        title: "Neugierig?<br>Let's talk."
      },
      sectionOne: {
        copyOne:
          'Das bisherige Intranet der Software AG war umfangreich, aber veraltet. Mit dem Ende der CMS-Lizenz standen wir unter Zeitdruck, einen neuen digitalen Arbeitsraum zu schaffen. Stapelweise interne Newsletter und verstreute lokale Intranets zeigten uns: Es ist Zeit für eine Neuausrichtung.',
        titleOne: 'Die Herausforderung'
      },
      sectionTwo: {
        copyOne:
          '<strong>Ein globales Social Intranet, das die Talente des Unternehmens verbindet.</strong><br>' +
          '<br>' +
          '<strong>Mehr als nur ein Arbeitsmittel:</strong> Das neue Intranet fördert aktive Zusammenarbeit, bietet maßgeschneiderten Inhalt dank Personalisierung und öffnet die Tür für integrierte Kommunikation. Stellen Sie sich beispielsweise eine direkte Ansprache des Management Boards durch dedizierte Blogs vor …<br>' +
          '<strong>Die Kraft von Microsoft SharePoint nutzen:</strong> Bereits mit Lizenzen und Erfahrung ausgestattet, wurde SharePoint unser Ass im Ärmel für die zeitkritische Realisierung. Die Herausforderung? Rechtzeitige Befüllung des Contents. Unsere Lösung: ein schrittweiser Ansatz. Wir planen mit Releases, die das neue Intranet immer weiterbringen. Erst das Wesentliche, dann die sukzessive Verfeinerung dank Feedback.',
        titleOne: 'Die Vision',
        slider: {
          titleOne: 'Strategie'
        }
      },
      sectionThree: {
        copyOne:
          'Durch Interviews mit Mitarbeitenden, ein Expertenteam von LINGNER.COM und verschiedenen Software AG Abteilungen setzten wir uns ein ambitioniertes Ziel: Go-Live beim internen Sales Event der Software AG. Unser Ziel war nicht die Kopie alter Inhalte, sondern die sorgfältige Auswahl und Präsentation des Relevanten.<br>' +
          'Dank der integrierten Funktionen von SharePoint, wie Viva Engage (ehemals Yammer), blühte unser Social Intranet auf. Aktive Foto-Communities und ein Forum zum Thema Social Intranet entstanden.',
        copyTwo: `Weg mit der Newsletter-Flut und hallo gezielte Nachrichten und Events über SharePoint's Personalisierungsfunktionen. Unsere Kommunikationsstrategie? Neugier wecken, neue Funktionen vorstellen und das kontinuierliche Wachstum unseres Intranets feiern.<br>' +
          'Die große Enthüllung beinhaltete eine geführte Videotour durch die Mitarbeitenden der Software AG, die die neue Plattform für alle verständlich machten.`,
        titleOne: 'Umsetzung',
        slider: {
          slideOne: {
            titleOne:
              '"Ein erfolgreicher Start, dank der hervorragenden Arbeit von LINGNER.COM. Die geführte Tour erleichterte den Übergang. Auf zur Kollaboration und Innovation!"',
            copyOne: 'Dr. Astrid Kasper, SVP Global Corporate Communications'
          },
          slideTwo: {
            titleOne:
              '"Die Zusammenarbeit mit LINGNER.COM war ein Wendepunkt. Unser globales Team freut sich nun über eine topmoderne Kollaborations- und Informationsplattform."',
            copyOne: 'Dr. Elke Frank, CHRO Software AG'
          },
          titleOne: 'Das sagen unsere Kunden:'
        }
      }
    },
    heidelbergDrupa: {
      mainHero: {
        subTitle: 'Etatgewinn HEIDELBERGER Druckmaschinen'
      },
      highlights: {
        customer: 'HEIDELBERGER Druckmaschinen AG',
        highlightOne: 'Idee und Konzept',
        highlightTwo: 'Video Produktion',
        highlightThree: 'Begleitende Social Media Kommunikation',
        highlightFour: 'Microsite'
      },
      outlinks: {
        linkTwo: {
          linkText: 'SharePoint Intranet',
          linkUrl: '/software-ag-das-intranet-neu-gedacht/',
          title: 'Nächster Fall'
        },
        title: 'Das könnte dich auch interessieren'
      },
      contactBox: {
        // eslint-disable-next-line quotes
        title: "Neugierig?<br>Let's talk."
      },
      sectionOne: {
        copyOne:
          'Der Maschinenbauer möchte schon vor der erstmals wieder stattfindenden Leitmesse Gesprächsanlässe zu zentralen Branchenthemen wie Fachkräftemangel, Digitalisierung und Nachhaltigkeit in einem innovativen und mutigen Auftritt bieten',
        titleOne: 'Die Herausforderung'
      },
      sectionTwo: {
        copyOne:
          '<strong>"Unfold Your Potential": Eine Kommunikationsvision wird Realität</strong>',
        copyTwo:
          'In unserer Virtual Production-Umgebung erlebt Nick Trabold, Global Head of Print Media Center, den HEIDELBERG Messestand schon einmal vorab. Die Besucher werden auf eine inspirierende Reise durch die Welt der Drucklösungen mitgenommen. Ein digitales Abbild des Messestands dient als Fernsehstudio. Experten kommen zu Wort und Produktinnovationen werden schon als „digitaler Zwilling“ erlebbar, bevor man auf der Messe richtig Hand anlegen kann.',
        copyThree:
          'Wir wollen mit dem Konzept die Möglichkeiten schaffen, der Zielgruppe etwas erlebbar zu machen, was bisher noch nicht existiert. Gleichzeitig ist der digitale Zwilling des Messestands auch konsistentes und zentrales Element für die Landing Page, Social Media und Newsletter.',
        titleOne: 'Die Strategie'
      },
      sectionThree: {
        copyOne:
          'Die von uns entworfene Landing Page ist das digitale Herzstück dieser Kampagne und bildet die Schnittstelle, auf der sich Neugier und Innovation vereinen. Von hier aus eröffnen wir den Weg zu einer interaktiven Erkundungstour, die durch strategisch platzierte Social Media-Inhalte und gezielte EDMs ergänzt wird. Dieses Netz aus digitalen Berührungspunkten ist das Ergebnis einer holistischen Symbiose aus Strategie, Design, Produktion und Distribution – alles aus der Hand der B2B-Spezialisten von LINGNER.COM.',
        copyTwo:
          'Die Vlogs wurden in den inverve studios in Heilbronn gedreht, wo wir mithilfe des digitalen Messestands, mit dem der Host im Vlog interagiert, beeindruckende virtuelle Einblicke ermöglichen.',
        titleOne: 'Die Umsetzung'
      },
      sectionFour: {
        copyOne:
          'Die aktuelle Episode unseres Vlogs behandelt das Thema "Sustainability". Darin werden Lösungen präsentiert, wie Unternehmen auf den Mangel an Arbeitskräften und Fachleuten reagieren können.',
        copyTwo:
          '„Wir sind stolz darauf, mit HEIDELBERG zusammenzuarbeiten und dazu beizutragen, dass die drupa 2024 ein unvergessliches Ereignis wird,“ so Etatverantwortlicher und Partner Wojtek Misiewicz von LINGNER.COM',
        copyThree:
          'Neugierig geworden? Unter <a class="link link--secondary" href="https://drupa.heidelberg.com/de/" target="_blank">drupa.heidelberg.com</a> kann das Konzept „live“ betrachtet werden.'
      },
      sectionFive: {
        slideOne: {
          copyOne: 'Sebastian Gottuck, HEIDELBERGER Druckmaschinen AG',
          titleOne:
            '„Ein starkes Konzept für einen starken Auftritt! Wir freuen uns riesig über die positive Resonanz der ersten Folgen. So können wir unsere Zielgruppe bereits vor der drupa beeindrucken und für unsere Lösungen interessieren.“'
        },
        titleOne: 'Das sagt unser Kunde'
      }
    }
  },
  contactSection: {
    title: 'Und so erreichst du uns.',
    contacts: {
      general: {
        title: 'Für alle Anliegen.',
        phone: '07131 - 642 90 30',
        phoneLink: 'tel:+49071316429030',
        email: 'info@lingner.com'
      },
      recruiting: {
        title: 'Für Bewerbungen.',
        nameRole: 'Carola Rogge<br>People & Culture',
        phone: '07131 - 642 90 47',
        phoneLink: 'tel:+49071316429047',
        email: 'jobs@lingner.com'
      }
    },
    locations: {
      title: 'Hier findest du uns',
      villaMertz: {
        title: 'Die Villa Mertz',
        description:
          'Unser Headquarter. Das Mutterschiff. Die Basis. Der Nukleus unseres kreativen Geistes. Mitten im Grünen und nahe am Wasser gebaut, bietet die klassizistische Villa uns Heimat und Inspiration.',
        address:
          'Rosenberg 1<br>74072 Heilbronn<br><a class="link link--tertiary link--has-underline link--has-underline" href="/assets/static/anfahrt-lingner-villa.pdf" target="_blank">Anfahrtsskizze</a>'
      },
      inverveStudios: {
        title:
          '<a class="link link--tertiary" href="https://inverve.io" target="_blank">Die inverve studios</a>',
        description:
          'Das Produktionshaus für deine digitalen Produktionen. AR, VR, MR oder Live-Event? Egal was dir vorschwebt, die 100m² große Bühne in Kombination mit der LED-Wall macht es möglich.',
        address:
          'Hafenstraße 11 - 13<br>74076 Heilbronn<br><a class="link link--tertiary link--has-underline link--has-underline" href="/assets/static/anfahrt-inverve-studios.pdf" target="_blank">Anfahrtsskizze</a>'
      },
      werk2: {
        title:
          '<a class="link link--tertiary" href="https://werk2.lingner.com" target="_blank">Das LINGNER.WERK2</a>',
        description:
          'Die Business Event Location in Heilbronn ist Konferenzraum für Workshops, Tagungen, Meetings und Seminare. Nur echt mit Flipper.',
        address:
          'Salzstraße 42<br>74076 Heilbronn<br><a class="link link--tertiary link--has-underline link--has-underline" href="/assets/static/anfahrt-lingner-werk2.pdf" target="_blank">Anfahrtsskizze</a>'
      }
    }
  },
  dataProtectionPage: {
    sectionOne: {
      title: '1. Verantwortlichkeiten und Kontaktdaten',
      copyOne:
        'Diese Datenschutz-Information gilt für die Datenverarbeitung durch:',
      copyTwo:
        'Verantwortlicher: Stefan Lingner und Tobias Hurrle (Geschäftsführer) LINGNER CONSULTING NEW MEDIA GMBH (im Folgenden: Lingner), Rosenberg 1, 74072 Heilbronn, Deutschland',
      phoneFax: 'Telefon: 0 71 31 / 642 90 30<br>Telefax: 0 79 43 / 94 27-70',
      copyThree:
        'Der externe betriebliche Datenschutzbeauftragte von LINGNER.COM ist unter der Anschrift Ferdinand-Braun-Straße 26, 74074 Heilbronn, beziehungsweise unter  <a class="link link--primary link--has-underline" href="mailto:schoener@adrem-gmbh.de">schoener@adrem-gmbh.de</a> erreichbar.'
    },
    sectionTwo: {
      title:
        '2. Erhebung und Speicherung personenbezogener Daten sowie Art und Zweck von deren Verwendung',
      subparagraphA: {
        title: 'a) Beim Besuch der Website',
        copyOne:
          'Beim Aufrufen unserer Website www.lingner.com werden durch den auf Ihrem Endgerät zum Einsatz kommenden Browser automatisch Informationen an den Server unserer Website gesendet. Diese Informationen werden temporär in einem sog. Logfile gespeichert.',
        copyTwo:
          'Folgende Informationen werden dabei ohne Ihr Zutun erfasst und bis zur automatisierten Löschung gespeichert:',
        listOne: {
          itemOne: 'IP-Adresse des anfragenden Rechners,',
          itemTwo: 'Datum und Uhrzeit des Zugriffs,',
          itemThree: 'Name und URL der abgerufenen Datei,',
          itemFour: 'Website, von der aus der Zugriff erfolgt (Referrer-URL),',
          itemFive:
            'verwendeter Browser und ggf. das Betriebssystem Ihres Rechners sowie der Name Ihres Access-Providers.'
        },
        copyThree:
          'Die genannten Daten werden durch uns zu folgenden Zwecken verarbeitet:',
        listTwo: {
          itemOne:
            'Gewährleistung eines reibungslosen Verbindungsaufbaus der Website,',
          itemTwo: 'Gewährleistung einer komfortablen Nutzung unserer Website,',
          itemThree: 'Auswertung der Systemsicherheit und -stabilität sowie',
          itemFour: 'zu weiteren administrativen Zwecken.'
        },
        copyFour:
          'Die Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 S. 1 lit. f DSGVO. Unser berechtigtes Interesse folgt aus oben aufgelisteten Zwecken zur Datenerhebung. In keinem Fall verwenden wir die erhobenen Daten zu dem Zweck, Rückschlüsse auf Ihre Person zu ziehen.',
        copyFive:
          'Darüber hinaus setzen wir beim Besuch unserer Website Cookies sowie Analysedienste ein. Nähere Erläuterungen dazu erhalten Sie unter den Ziff. 4 und 5 dieser Datenschutzerklärung.'
      }
    },
    sectionThree: {
      title: '3. Newsletter-Anmeldung und Zusendung von Inhalten',
      copyOne:
        'Wenn Sie sich für unseren Newsletter anmelden, erheben wir die von Ihnen im Anmeldeformular angegebenen personenbezogenen Daten (z. B. Name und E-Mail-Adresse). Die Verarbeitung dieser Daten erfolgt auf Grundlage Ihrer Einwilligung gemäß Art. 6 Abs. 1 S. 1 lit. a DSGVO. Zweck der Verarbeitung ist die Zusendung unseres Newsletters mit aktuellen Informationen sowie gegebenenfalls ergänzende Inhalte, wie zum Beispiel exklusive Studien, Whitepapers oder andere Materialien.',
      copyTwo:
        'Ihre Einwilligung zur Verarbeitung Ihrer personenbezogenen Daten können Sie jederzeit mit Wirkung für die Zukunft widerrufen, indem Sie den Abmeldelink im Newsletter verwenden oder uns eine E-Mail an <a class="link link--primary link--has-underline" href="mailto:info@lingner.com">info@lingner.com</a> senden. Die Daten werden nach Widerruf Ihrer Einwilligung gelöscht, sofern keine gesetzlichen Aufbewahrungspflichten entgegenstehen.'
    },
    sectionFour: {
      title: '4. Weitergabe von Daten',
      copyOne:
        'Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als den im Folgenden aufgeführten Zwecken findet nicht statt.',
      copyTwo: 'Wir geben Ihre persönlichen Daten nur an Dritte weiter, wenn:',
      listOne: {
        itemOne:
          'Sie Ihre nach Art. 6 Abs. 1 S. 1 lit. a DSGVO ausdrückliche Einwilligung dazu erteilt haben,',
        itemTwo:
          'die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. f DSGVO zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist und kein Grund zur Annahme besteht, dass Sie ein überwiegendes schutzwürdiges Interesse an der Nichtweitergabe Ihrer Daten haben,',
        itemThree:
          'für den Fall, dass für die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. c DSGVO eine gesetzliche Verpflichtung besteht, sowie',
        itemFour:
          'dies gesetzlich zulässig und nach Art. 6 Abs. 1 S. 1 lit. B DSGVO für die Abwicklung von Vertragsverhältnissen mit Ihnen erforderlich ist.'
      }
    },
    sectionFive: {
      title: '5. Kontaktformular',
      copyOne:
        'Wenn Sie uns über ein auf unserer Website bereitgestelltes Kontaktformular kontaktieren, verarbeiten wir die von Ihnen angegebenen Daten (z. B. Name, E-Mail-Adresse und Anfrageinhalt), um Ihre Anfrage zu bearbeiten. Die Verarbeitung erfolgt auf Grundlage Ihrer Einwilligung gemäß Art. 6 Abs. 1 S. 1 lit. a DSGVO.',
      copyTwo:
        'Ihre Daten werden nur für die Bearbeitung Ihrer Anfrage und etwaiger Anschlussfragen verwendet und nach Beendigung des Kontakts gelöscht, sofern keine gesetzlichen Aufbewahrungspflichten bestehen. Ihre Einwilligung können Sie jederzeit mit Wirkung für die Zukunft widerrufen, indem Sie uns eine E-Mail an <a class="link link--primary link--has-underline" href="mailto:info@lingner.com">info@lingner.com</a> senden.'
    },
    sectionSix: {
      title: '6. Cookies',
      copyOne:
        'Wir setzen auf unserer Seite Cookies ein. Hierbei handelt es sich um kleine Dateien, die Ihr Browser automatisch erstellt und die auf Ihrem Endgerät (Laptop, Tablet, Smartphone o.ä.) gespeichert werden, wenn Sie unsere Seite besuchen. Cookies richten auf Ihrem Endgerät keinen Schaden an, enthalten keine Viren, Trojaner oder sonstige Schadsoftware. In dem Cookie werden Informationen abgelegt, die sich jeweils im Zusammenhang mit dem spezifisch eingesetzten Endgerät ergeben. Dies bedeutet jedoch nicht, dass wir dadurch unmittelbar Kenntnis von Ihrer Identität erhalten.',
      copyTwo:
        'Der Einsatz von Cookies dient einerseits dazu, die Nutzung unseres Angebots für Sie angenehmer zu gestalten. So setzen wir sogenannte Session-Cookies ein, um zu erkennen, dass Sie einzelne Seiten unserer Website bereits besucht haben. Diese werden nach Verlassen unserer Seite automatisch gelöscht. Darüber hinaus setzen wir ebenfalls zur Optimierung der Benutzerfreundlichkeit temporäre Cookies ein, die für einen bestimmten festgelegten Zeitraum auf Ihrem Endgerät gespeichert werden. Besuchen Sie unsere Seite erneut, um unsere Dienste in Anspruch zu nehmen, wird automatisch erkannt, dass Sie bereits bei uns waren und welche Eingaben und Einstellungen sie getätigt haben, um diese nicht noch einmal eingeben zu müssen. Die durch diese Cookies verarbeiteten Daten sind für die genannten Zwecke zur Wahrung unserer berechtigten Interessen sowie der Dritter nach Art. 6 Abs. 1 S. 1 lit. f DSGVO erforderlich.',
      copyThree:
        'Zum anderen setzten wir Cookies ein, um die Nutzung unserer Website statistisch zu erfassen und zum Zwecke der Optimierung unseres Angebotes für Sie auszuwerten (siehe Ziff. 5). Diese Cookies ermöglichen es uns, bei einem erneuten Besuch unserer Seite automatisch zu erkennen, dass Sie bereits bei uns waren. Diese Cookies werden nach einer jeweils definierten Zeit automatisch gelöscht. Die durch diese Cookies verarbeiteten Daten sind für die genannten Zwecke nur nach Ihrer Einwilligung nach Art. 6 Abs. 1 S. 1 lit. a DSGVO möglich.',
      copyFour:
        'Lingner ermöglicht es Ihnen die Nutzung von Cookies zu steuern. Klicken Sie dazu auf den Link "Cookie-Settings" in der Fußzeile. Hier können Sie jederzeit einstellen, welche Cookies Sie verwenden möchten. Wir teilen Cookies in folgende Kategorien ein:',
      copyFive:
        '<em>Notwendige Cookies (Stufe 1)</em><br>Diese Cookies sind zwingend erforderlich, damit unsere Websites und deren Funktionen ordnungsgemäß arbeiten, z.B. das Speichern Ihrer Cookie-Einstellungen.',
      copySix:
        '<em>Benutzereinstellungen (Stufe 2)</em><br>Diese Cookies speichern Ihre Benutzereinstellungen, um unsere Website den Anforderungen unserer Besucher anzupassen und zu optimieren.',
      copySeven:
        '<em>Analytische Cookies (Stufe 3)</em><br>Diese Cookies sammeln Informationen darüber, wie Sie unsere Websites verwenden.',
      copyEight:
        'Die meisten Browser akzeptieren Cookies automatisch. Sie können Ihren Browser jedoch so konfigurieren, dass keine Cookies auf Ihrem Computer gespeichert werden oder stets ein Hinweis erscheint, bevor ein neuer Cookie angelegt wird. Die vollständige Deaktivierung von Cookies kann jedoch dazu führen, dass Sie nicht alle Funktionen unserer Website nutzen können.'
    },
    sectionSeven: {
      title: '7. Tracking-Tools',
      copyOne:
        'Die im Folgenden aufgeführten und von uns eingesetzten Tracking-Maßnahmen werden auf Grundlage des Art. 6 Abs. 1 S. 1 lit. a DSGVO durchgeführt. Mit den zum Einsatz kommenden Tracking-Maßnahmen wollen wir eine bedarfsgerechte Gestaltung und die fortlaufende Optimierung unserer Webseite sicherstellen. Zum anderen setzen wir die Tracking-Maßnahmen ein, um die Nutzung unserer Webseite statistisch zu erfassen und zum Zwecke der Optimierung unseres Angebotes für Sie auszuwerten. Diese Interessen sind als berechtigt im Sinne der vorgenannten Vorschrift anzusehen. Die jeweiligen Datenverarbeitungszwecke und Datenkategorien sind aus den entsprechenden Tracking-Tools zu entnehmen.',
      subparagraphA: {
        title: 'a) Google Analytics',
        copyOne:
          'Zum Zwecke der bedarfsgerechten Gestaltung und fortlaufenden Optimierung unserer Seiten nutzen wir Google Analytics, ein Webanalysedienst der Google Inc. (<a class="link link--primary link--has-underline" href="https://www.google.de/intl/de/about/">https://www.google.de/intl/de/about/</a>) (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; im Folgenden „Google“). In diesem Zusammenhang werden pseudonymisierte Nutzungsprofile erstellt und Cookies (siehe unter Ziff. 4) verwendet. Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Website wie',
        listOne: {
          itemOne: 'Browser-Typ/-Version,',
          itemTwo: 'verwendetes Betriebssystem,',
          itemThree: 'Referrer-URL (die zuvor besuchte Seite),',
          itemFour: 'Hostname des zugreifenden Rechners (IP-Adresse),',
          itemFive: 'Uhrzeit der Serveranfrage,'
        },
        copyTwo:
          'werden an einen Server von Google in den USA übertragen und dort gespeichert. Die Informationen werden verwendet, um die Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen zu Zwecken der Marktforschung und bedarfsgerechten Gestaltung dieser Internetseiten zu erbringen. Auch werden diese Informationen gegebenenfalls an Dritte übertragen, sofern dies gesetzlich vorgeschrieben ist oder soweit Dritte diese Daten im Auftrag verarbeiten. Es wird in keinem Fall Ihre IP-Adresse mit anderen Daten von Google zusammengeführt. Die IP-Adressen werden anonymisiert, so dass eine Zuordnung nicht möglich ist (IP-Masking). Sie können die Installation der Cookies durch eine entsprechende Einstellung der Browser-Software verhindern; wir weisen jedoch darauf hin, dass in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich genutzt werden können.',
        coptThree:
          'Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie ein Browser-Add-on herunterladen und installieren (<a class="link link--primary link--has-underline" href="https://tools.google.com/dlpage/gaoptout?hl=de">https://tools.google.com/dlpage/gaoptout?hl=de</a>).',
        copyFour:
          'Weitere Informationen zum Datenschutz im Zusammenhang mit Google Analytics finden Sie etwa in der Google Analytics-Hilfe (<a class="link link--primary link--has-underline" href="https://support.google.com/analytics/answer/6004245?hl=de">https://support.google.com/analytics/answer/6004245?hl=de</a>).'
      },
      subparagraphB: {
        title: 'b) Google Tag Manager',
        copyOne:
          'Wir nutzen auf unserer Webseite den Google Tag Manager. Der Google Tag Manager ist ein Dienst der Google Ireland Limited („Google“), Gordon House, Barrow Street, Dublin 4, Irland.',
        copyTwo:
          'Durch den Google Tag Manager können wir verschiedene Codes und Dienste geordnet und vereinfacht auf unserer Webseite einbinden. Der Google Tag Manager implementiert dabei die Tags bzw. „löst“ die eingebundenen Tags aus. Beim Auslösen eines Tags verarbeitet Google unter Umständen Informationen (auch personenbezogene Daten) und verarbeitet diese. Dabei kann nicht ausgeschlossen werden, dass Google die Informationen auch an einen Server in einem Drittland übermittelt.',
        copyThree:
          'Insbesondere werden folgende personenbezogenen Daten durch den Google Tag Manager verarbeitet:',
        listOne: {
          itemOne: 'Online-Kennzeichnungen (einschließlich Cookie-Kennungen)',
          itemTwo: 'IP-Adresse'
        },
        copyFour:
          'Darüber hinaus finden Sie weitere detaillierte Informationen zu dem Google Tag Manager auf den Webseiten <a class="link link--primary link--has-underline" href="https://www.google.com/tagmanager/use-policy.html">https://www.google.com/tagmanager/use-policy.html</a> sowie unter <a class="link link--primary link--has-underline" href="https://www.google.com/intl/de/policies/privacy/index.html">https://www.google.com/intl/de/policies/privacy/index.html</a> unter dem Abschnitt „Daten, die wir aufgrund Ihrer Nutzung unserer Dienste erhalten“.',
        copyFive:
          'Weiterhin haben wir für den Einsatz des Google Tag Managers mit Google einen Vertrag zur Auftragsverarbeitung geschlossen (Art. 28 DSGVO). Google verarbeitet die Daten in unserem Auftrag, um die hinterlegten Tags auszulösen und die Dienste auf unserer Webseite darzustellen. Google kann diese Informationen gegebenenfalls an Dritte übertragen, sofern dies gesetzlich vorgeschrieben ist oder soweit Dritte diese Daten im Auftrag von Google verarbeiten.',
        copySix:
          'Sofern Sie einzelne Trackingdienste deaktiviert haben (z.B. durch das Setzenlassen eines Opt-Out-Cookies), bleibt die Deaktivierung für alle betroffenen Tracking-Tags bestehen, die durch den Google Tag Manager eingebunden werden.',
        copySeven:
          'Durch die Einbindung des Google Tag Managers verfolgen wir den Zweck, eine vereinfachte und übersichtliche Einbindung verschiedener Dienste vornehmen zu können. Darüber hinaus optimiert die Einbindung des Google Tag Managers die Ladezeiten der verschiedenen Dienste.',
        copyEight:
          'Rechtsgrundlage für die hier beschriebene Verarbeitung personenbezogener Daten im Rahmen des Messverfahrens ist eine von Ihnen ausdrücklich erteilte Einwilligung gem. Art. 6 Abs. 1 lit. a) DSGVO.'
      },
      subparagraphC: {
        title: 'c) Google Adwords Conversion Tracking',
        copyOne:
          'Um die Nutzung unserer Webseite statistisch zu erfassen und zum Zwecke der Optimierung unserer Website für Sie auszuwerten, nutzen wir ferner das Google Conversion Tracking. Dabei wird von Google Adwords ein Cookie (siehe Ziffer 4) auf Ihrem Rechner gesetzt, sofern Sie über eine Google-Anzeige auf unsere Webseite gelangt sind. Diese Cookies verlieren nach 30 Tagen ihre Gültigkeit und dienen nicht der persönlichen Identifizierung. Besucht der Nutzer bestimmte Seiten der Webseite des Adwords-Kunden und das Cookie ist noch nicht abgelaufen, können Google und der Kunde erkennen, dass der Nutzer auf die Anzeige geklickt hat und zu dieser Seite weitergeleitet wurde. Jeder Adwords-Kunde erhält ein anderes Cookie. Cookies können somit nicht über die Webseiten von Adwords-Kunden nachverfolgt werden. Die mithilfe des Conversion-Cookies eingeholten Informationen dienen dazu, Conversion-Statistiken für Adwords-Kunden zu erstellen, die sich für Conversion- Tracking entschieden haben. Die Adwords-Kunden erfahren die Gesamtanzahl der Nutzer, die auf ihre Anzeige geklickt haben und zu einer mit einem Conversion-Tracking-Tag versehenen Seite weitergeleitet wurden. Sie erhalten jedoch keine Informationen, mit denen sich Nutzer persönlich identifizieren lassen.',
        copyTwo:
          'Wenn Sie nicht an dem Tracking-Verfahren teilnehmen möchten, können Sie auch das hierfür erforderliche Setzen eines Cookies ablehnen – etwa per Browser-Einstellung, die das automatische Setzen von Cookies generell deaktiviert. Sie können Cookies für Conversion-Tracking auch deaktivieren, indem Sie Ihren Browser so einstellen, dass Cookies von der Domain „www.googleadservices.com“ blockiert werden.',
        copyThree:
          'Googles Datenschutzbelehrung zum Conversion-Tracking finden Sie hier<br>(<a class="link link--primary link--has-underline" href="https://services.google.com/sitestats/de.html">https://services.google.com/sitestats/de.html</a>).'
      }
    },
    sectionEight: {
      title: '8. Social Media Plug-ins',
      copyOne:
        'Wir setzen auf unserer Website auf Grundlage des Art. 6 Abs. 1 S. 1 lit. a DSGVO Social Plug-ins der sozialen Netzwerke Youtube und Vimeo ein, um unsere Agentur hierüber bekannter zu machen. Der dahinterstehende werbliche Zweck ist als berechtigtes Interesse im Sinne der DSGVO anzusehen. Die Verantwortung für den datenschutzkonformen Betrieb ist durch deren jeweiligen Anbieter zu gewährleisten. Die Einbindung dieser Plug-ins durch uns erfolgt im Wege der sogenannten Zwei-Klick-Methode um Besucher unserer Webseite bestmöglich zu schützen.',
      subparagraphA: {
        title: 'a) YouTube',
        copyOne:
          'Auf unseren Internetseiten sind Plugins des Videostreamingdienstes der YouTube LLC (Youtube) integriert. Die Youtube-Plugins (Video-Embed) erkennen Sie an dem Youtube-Logo auf unserer Seite. Wenn Sie eine Seite unseres Webauftritts aufrufen, die ein solches Plugin enthält, wird eine direkte Verbindung zwischen Ihrem Browser und dem Youtube-Server hergestellt. Youtube erhält dadurch die Information, dass Sie mit Ihrer IP-Adresse unsere Seite besucht haben. Wenn Sie den Youtube „Play-Button“ anklicken, während Sie in Ihrem Youtube-Account eingeloggt sind, können Sie die Inhalte unserer Seiten auf Ihrem Youtube-Profil verlinken. Dadurch kann Youtube den Besuch unserer Seiten Ihrem Benutzerkonto zuordnen. Wir weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung durch Youtube erhalten. Wenn Sie nicht wünschen, dass Youtube den Besuch unserer Seiten zuordnen kann, loggen Sie sich bitte aus Ihrem Youtube-Benutzerkonto aus. Weitere Informationen hierzu finden Sie in der Datenschutzerklärung von Youtube (<a class="link link--primary link--has-underline" href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a>).'
      },
      subparagraphB: {
        title: 'b) Vimeo',
        copyOne:
          'Auf unseren Internetseiten sind Plugins des Videostreamingdienstes der Vimeo Inc. (Vimeo) integriert. Die Vimeo-Plugins (Video-Embed) erkennen Sie an dem Vimeo-Logo auf unserer Seite. Wenn Sie eine Seite unseres Webauftritts aufrufen, die ein solches Plugin enthält, wird eine direkte Verbindung zwischen Ihrem Browser und dem Vimeo-Server hergestellt. Vimeo erhält dadurch die Information, dass Sie mit Ihrer IP-Adresse unsere Seite besucht haben. Wenn Sie den Vimeo „Play-Button“ anklicken, während Sie in Ihrem Vimeo-Account eingeloggt sind, können Sie die Inhalte unserer Seiten auf Ihrem Vimeo-Profil verlinken. Dadurch kann Vimeo den Besuch unserer Seiten Ihrem Benutzerkonto zuordnen. Wir weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung durch Vimeo erhalten. Wenn Sie nicht wünschen, dass Vimeo den Besuch unserer Seiten zuordnen kann, loggen Sie sich bitte aus Ihrem Vimeo-Benutzerkonto aus. Weitere Informationen hierzu finden Sie in der Datenschutzerklärung von Vimeo (<a class="link link--primary link--has-underline" href="https://vimeo.com/privacy">https://vimeo.com/privacy</a>).'
      },
      subparagraphC: {
        title: 'c) Facebook',
        copyOne:
          'Auf unserer Website sind Elemente des sozialen Netzwerks Facebook integriert. Anbieter dieses Dienstes ist die Meta Platforms Ireland Limited, 4 Grand Canal Square, Dublin 2, Irland. Die erfassten Daten werden nach Aussage von Facebook jedoch auch in die USA und in andere Drittländer übertragen.',
        copyTwo:
          'Eine Übersicht über die Facebook Social-Media-Elemente finden Sie hier:<br><a class="link link--primary link--has-underline" href="https://developers.facebook.com/docs/plugins/?locale=de_DE">https://developers.facebook.com/docs/plugins/?locale=de_DE</a>.',
        copyThree:
          'Wenn das Social-Media-Element aktiv ist, wird eine direkte Verbindung zwischen Ihrem Endgerät und dem Facebook-Server hergestellt. Facebook erhält dadurch die Information, dass Sie mit Ihrer IP-Adresse diese Website besucht haben. Wenn Sie den Facebook „Like-Button“ anklicken, während Sie in Ihrem Facebook-Account eingeloggt sind, können Sie die Inhalte dieser Website auf Ihrem Facebook-Profil verlinken. Dadurch kann Facebook den Besuch dieser Website Ihrem Benutzerkonto zuordnen. Wir weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung durch Facebook erhalten. Weitere Informationen hierzu finden Sie in der Datenschutzerklärung von Facebook unter:<br><a class="link link--primary link--has-underline" href="https://de-de.facebook.com/privacy/explanation">https://de-de.facebook.com/privacy/explanation</a>.',
        copyFour:
          'Soweit eine Einwilligung (Consent) eingeholt wurde, erfolgt der Einsatz des o. g. Dienstes auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 TTDSG. Die Einwilligung ist jederzeit widerrufbar. Soweit keine Einwilligung eingeholt wurde, erfolgt die Verwendung des Dienstes auf Grundlage unseres berechtigten Interesses an einer möglichst umfassenden Sichtbarkeit in den Sozialen Medien.',
        copyFive:
          'Soweit mit Hilfe des hier beschriebenen Tools personenbezogene Daten auf unserer Website erfasst und an Facebook weitergeleitet werden, sind wir und die Meta Platforms Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland gemeinsam für diese Datenverarbeitung verantwortlich (Art. 26 DSGVO). Die gemeinsame Verantwortlichkeit beschränkt sich dabei ausschließlich auf die Erfassung der Daten und deren Weitergabe an Facebook. Die nach der Weiterleitung erfolgende Verarbeitung durch Facebook ist nicht Teil der gemeinsamen Verantwortung. Die uns gemeinsam obliegenden Verpflichtungen wurden in einer Vereinbarung über gemeinsame Verarbeitung festgehalten. Den Wortlaut der Vereinbarung finden Sie unter:<br><a class="link link--primary link--has-underline" href="https://www.facebook.com/legal/controller_addendum">https://www.facebook.com/legal/controller_addendum</a>. Laut dieser Vereinbarung sind wir für die Erteilung der Datenschutzinformationen beim Einsatz des Facebook-Tools und für die datenschutzrechtlich sichere Implementierung des Tools auf unserer Website verantwortlich. Für die Datensicherheit der Facebook-Produkte ist Facebook verantwortlich. Betroffenenrechte (z. B. Auskunftsersuchen) hinsichtlich der bei Facebook verarbeiteten Daten können Sie direkt bei Facebook geltend machen. Wenn Sie die Betroffenenrechte bei uns geltend machen, sind wir verpflichtet, diese an Facebook weiterzuleiten.',
        copySix:
          'Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gestützt.<br>Details finden Sie hier:<br><a class="link link--primary link--has-underline" href="https://www.facebook.com/legal/EU_data_transfer_addendum">https://www.facebook.com/legal/EU_data_transfer_addendum</a>,<br><a class="link link--primary link--has-underline" href="https://de-de.facebook.com/help/566994660333381">https://de-de.facebook.com/help/566994660333381</a> und<br><a class="link link--primary link--has-underline" href="https://www.facebook.com/policy.php">https://www.facebook.com/policy.php</a>.'
      },
      subparagraphD: {
        title: 'd) LinkedIn',
        copyOne:
          'Diese Website nutzt Elemente des Netzwerks LinkedIn. Anbieter ist die LinkedIn Ireland Unlimited Company, Wilton Plaza, Wilton Place, Dublin 2, Irland.',
        copyTwo:
          'Bei jedem Abruf einer Seite dieser Website, die Elemente von LinkedIn enthält, wird eine Verbindung zu Servern von LinkedIn aufgebaut. LinkedIn wird darüber informiert, dass Sie diese Website mit Ihrer IPAdresse besucht haben. Wenn Sie den „Recommend-Button“ von LinkedIn anklicken und in Ihrem Account bei LinkedIn eingeloggt sind, ist es LinkedIn möglich, Ihren Besuch auf dieser Website Ihnen und Ihrem Benutzerkonto zuzuordnen. Wir weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung durch LinkedIn haben.',
        copyThree:
          'Soweit eine Einwilligung (Consent) eingeholt wurde, erfolgt der Einsatz des o. g. Dienstes auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 TTDSG. Die Einwilligung ist jederzeit widerrufbar. Soweit keine Einwilligung eingeholt wurde, erfolgt die Verwendung des Dienstes auf Grundlage unseres berechtigten Interesses an einer möglichst umfassenden Sichtbarkeit in den Sozialen Medien.',
        copyFour:
          'Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gestützt.<br>Details finden Sie hier:<br><a class="link link--primary link--has-underline" href="https://www.linkedin.com/help/linkedin/answer/62538/datenubertragung-aus-der-eu-dem-ewr-und-derschweiz?lang=de">https://www.linkedin.com/help/linkedin/answer/62538/datenubertragung-aus-der-eu-dem-ewr-und-derschweiz?lang=de</a>',
        copyFive:
          'Weitere Informationen hierzu finden Sie in der Datenschutzerklärung von LinkedIn unter:<br><a class="link link--primary link--has-underline" href="https://www.linkedin.com/legal/privacy-policy.">https://www.linkedin.com/legal/privacy-policy.</a>'
      }
    },
    sectionNine: {
      title: '9. Betroffenenrechte',
      copyOne: 'Sie haben das Recht:',
      listOne: {
        itemOne:
          'gemäß Art. 15 DSGVO Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten zu verlangen. Insbesondere können Sie Auskunft über die Verarbeitungszwecke, die Kategorie der personenbezogenen Daten, die Kategorien von Empfängern, gegenüber denen Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer, das Bestehen eines Rechts auf Berichtigung, Löschung, Einschränkung der Verarbeitung oder Widerspruch, das Bestehen eines Beschwerderechts, die Herkunft ihrer Daten, sofern diese nicht bei uns erhoben wurden, sowie über das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling und ggf. aussagekräftigen Informationen zu deren Einzelheiten verlangen;',
        itemTwo:
          'gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger oder Vervollständigung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen;',
        itemThree:
          'gemäß Art. 17 DSGVO die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen, soweit nicht die Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist;',
        itemFour:
          'gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, soweit die Richtigkeit der Daten von Ihnen bestritten wird, die Verarbeitung unrechtmäßig ist, Sie aber deren Löschung ablehnen und wir die Daten nicht mehr benötigen, Sie jedoch diese zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen oder Sie gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung eingelegt haben;',
        itemFive:
          'gemäß Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesebaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen;',
        itemSix:
          'gemäß Art. 7 Abs. 3 DSGVO Ihre einmal erteilte Einwilligung jederzeit gegenüber uns zu widerrufen. Dies hat zur Folge, dass wir die Datenverarbeitung, die auf dieser Einwilligung beruhte, für die Zukunft nicht mehr fortführen dürfen und',
        itemSeven:
          'gemäß Art. 77 DSGVO sich bei einer Aufsichtsbehörde zu beschweren. In der Regel können Sie sich hierfür an die Aufsichtsbehörde Ihres üblichen Aufenthaltsortes oder Arbeitsplatzes oder unseres Kanzleisitzes wenden.'
      }
    },
    sectionTen: {
      title: '10. Widerspruchsrecht',
      copyOne:
        'Sofern Ihre personenbezogenen Daten auf Grundlage von berechtigten Interessen gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO verarbeitet werden, haben Sie das Recht, gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten einzulegen, soweit dafür Gründe vorliegen, die sich aus Ihrer besonderen Situation ergeben oder sich der Widerspruch gegen Direktwerbung richtet. Im letzteren Fall haben Sie ein generelles Widerspruchsrecht, das ohne Angabe einer besonderen Situation von uns umgesetzt wird.',
      copyTwo:
        'Möchten Sie von Ihrem Widerrufs- oder Widerspruchsrecht Gebrauch machen, genügt eine E-Mail an <a class="link link--primary link--has-underline" href="mailto:info@lingner.com">info@lingner.com</a>.'
    },
    sectionEleven: {
      title: '11. Datensicherheit',
      copyOne:
        'Wir verwenden innerhalb des Website-Besuchs das verbreitete SSL-Verfahren (Secure Socket Layer) in Verbindung mit der jeweils höchsten Verschlüsselungsstufe, die von Ihrem Browser unterstützt wird. In der Regel handelt es sich dabei um eine 256 Bit Verschlüsselung. Falls Ihr Browser keine 256-Bit Verschlüsselung unterstützt, greifen wir stattdessen auf 128-Bit v3 Technologie zurück. Ob eine einzelne Seite unseres Internetauftrittes verschlüsselt übertragen wird, erkennen Sie an der geschlossenen Darstellung des Schüssel- beziehungsweise Schloss-Symbols in der unteren Statusleiste Ihres Browsers. Wir bedienen uns im Übrigen geeigneter technischer und organisatorischer Sicherheitsmaßnahmen, um Ihre Daten gegen zufällige oder vorsätzliche Manipulationen, teilweisen oder vollständigen Verlust, Zerstörung oder gegen den unbefugten Zugriff Dritter zu schützen. Unsere Sicherheitsmaßnahmen werden entsprechend der technologischen Entwicklung fortlaufend verbessert.'
    },
    sectionTwelve: {
      title: '12. Aktualität und Änderung dieser Datenschutzerklärung',
      copyOne:
        'Diese Datenschutzerklärung ist aktuell gültig und hat den Stand 2024. Durch die Weiterentwicklung unserer Website und Angebote darüber oder aufgrund geänderter gesetzlicher beziehungsweise behördlicher Vorgaben kann es notwendig werden, diese Datenschutzerklärung zu ändern. Die jeweils aktuelle Datenschutzerklärung kann jederzeit auf der Website unter  <a class="link link--primary link--has-underline" href="https://www.lingner.com/datenschutz/">www.lingner.com/datenschutz</a> von Ihnen abgerufen und ausgedruckt werden.'
    }
  },
  exampleCase: {
    externalCommunication: {
      contactBox: {
        // eslint-disable-next-line quotes
        title: "Neugierig?<br>Let's talk."
      },
      outlinks: {
        linkTwo: {
          linkText: 'SharePoint Intranet der Software AG',
          linkUrl: '/software-ag-das-intranet-neu-gedacht/',
          title: 'Nächster Fall'
        },
        title: 'Das könnte dich auch interessieren'
      },
      sectionOne: {
        copyOne:
          'Auch die B2B-Kommunikation überzeugt am Ende Menschen und nicht Maschinen. Deshalb muss auch eine sehr technische Marke den Nerv treffen, Emotionen wecken, Mehrwert stiften und dabei richtig kompetent kommunizieren.',
        copyTwo:
          'Wie überzeugt man aber eine zögernde Zielgruppe? Wie inszeniert man die Markteinführung eines neuen Produktes? Gemeinsam mit unserem Kunden Dentsply Sirona standen wir vor genau diesen Herausforderungen. Am Ende überzeugten wir durch eine außergewöhnlich gut orchestrierte B2B-Kampagne, welche wir kanalübergreifend und zielorientiert umgesetzt haben. Der Kampagnenfilm wurde durch eine umfangreiche Kampagne mit Fokus auf Social Media distribuiert und ergänzt durch Printanzeigen, sowie On- und Offline-Events. Damit erreichten wir eine größere Relevanz der Marke und echte Unterstützung für den Sales.',
        title: 'B2B Kampagnen & Digital Marketing'
      },
      sectionTwo: {
        copyOne:
          'Wenn eine große Zielgruppe mit wichtigen Inhalten abgeholt werden muss, konzipieren wir dazu das richtige Format und stellen die passende Bühne. Egal ob live, digital oder vor Ort. So wie für das Bundesministerium für Gesundheit, als es hieß „Gemeinsam gegen Corona“. Dies Nachricht sollten wir gemeinsam unter das Volk bringen. Hierfür entwickelten wir ein Streaming-Konzept und setzten es dann live in Berlin um. Die Streams mit dem damaligen Gesundheitsminister Jens Spahn erreichten Millionen von Zuschauer über alle wichtige TV-Medien und Social-Media-Kanäle hinweg.',
        copyTwo:
          'Zielgruppen können aber auch sehr spezifisch sein, wie bei der Interpharm Online. Dort galt es drei Tage lang mit Educational-Content und Top-Speakern alle Apotheker:innen da draußen abzuholen. Wir konzipierten das gesamte Online-Event und setzten es vor Ort um. Die Begeisterung war riesig und der Mehrwert garantiert. Vor allem erklärungsbedürftige, schwer zu transportierende Produkte, oder solche die sich noch in der Entwicklungsphase befinden, profitieren von einem „Digitalen Zwilling“ Mithilfe eines digitalen Abbildes des Produktes wird es nicht nur möglich die digitale und reale Welt verschmelzen zu lassen, sondern auch Vorgänge und Prozesse zu zeigen, welche in der realen Welt mit bloßem Auge nicht zu erkennen sind.',
        title: 'Live Kommunikation'
      },
      sectionThree: {
        copyOne:
          'Nicht nur Personen, sondern auch Produkte und Lösungen brauchen eine (Live)-Bühne. Denn am Ende beziehen wir Menschen unsere Produkte am liebsten von Marken, die wir kennen und denen wir vertrauen. Für den Weltmarktführer Weinig AG (Maschinen und Systeme zur Massivholzverarbeitung) entwickelten wir mit der Solid Wood Summit ein einzigartiges digitales Messe-Format und eine eigene digitale Plattform. Drei Tage lang konnten Podiumsdiskussionen, Impulsvorträge und Experteninterviews rund um den Globus live gestreamt werden. Eine massive Innovation für die gesamte Branche.',
        copyTwo:
          'Auch mit unserem Kunden Warema gelang es uns mit dem Trendkongress für die Sonnenschutzbranche neue Maßstäbe in Sachen Dialog, Weiterbildung und Inspiration zu setzen. Gemeinsam entwickelten wir das neue Veranstaltungsformat – und der Plan ging auf: Rund 120 Fachpartner stellten sich aus dem vielfältigen Angebot ihr eigenes Programm zusammen und nutzten zugleich die Gelegenheit, über die aktuellen Branchenthemen zu diskutieren. Wir begleiteten das gesamte Event von der Konzeption bis hin zur technischen Umsetzung.',
        copyThree:
          'Live Events profitieren ganz besonders von der Technologie des digitalen Zwillings. Dieses virtuelle Abbild ermöglicht es in Live-Events aufmerksamkeitsstark und leicht verständlich komplexe Zusammenhänge oder Abläufe zu visualisieren.',
        title: 'Live Sales'
      },
      sectionFour: {
        copyOne:
          'Auf einem globalen Level gilt es als Erfolgs-Booster, unternehmensweite Standards für die Social Media Kommunikation zu schaffen, Synergien zu nutzen und Erfolge messbar zu machen. Themen wie Absenderstruktur, Guidelines und Reaktionsprozesse sind nur einige der relevanten Punkte in einem globalen Strategieprozess.',
        copyTwo:
          'Unsere Erfahrungen aus der Zusammenarbeit mit Unternehmen wie QIAGEN, RENOLIT oder Kühne & Nagel zeigen, dass Prozesse und Standards im internationalen Austausch mit den Verantwortlichen zu definieren sind. Ziel ist es, die Kommunikatoren zu empowern. Hierfür wird eine klare Linie mit viel Gestaltungsspielraum für den Zielgruppen-spezifischen Dialog erarbeitet.',
        copyThree:
          'Lassen Sie uns gemeinsam auch Ihre globale Social Media-Landschaft gestalten. Wie das aussehen kann, können Sie direkt von QIAGEN erfahren. Hier geht es zum Podcast mit Dr. Thomas Theuringer, Senior Director Corporate Communications & Head of External Communications.',
        copyFour:
          'Ob wir von Employee Advocacy, Corporate Influencer:innen oder Mitarbeitenden als Markenbotschafter:innen sprechen, die Botschaft bleibt die gleiche: Auch im B2B sind Mitarbeitende die Kommunikatoren von morgen. Das gilt für Führungskräfte ebenso wie die Praktikant:innen.',
        copyFive:
          'Wir begleiten Sie auf dem Weg zum Corporate Influencer-Programm und platzieren ihre Executives in den Sozialen Medien. Wir etablieren neue Personal Brands und platzieren die Thought Leader Ihrer Branche.',
        subTitleOne: 'Social Media global denken (Social Media Empowerment)',
        subTitleTwo: 'Next level',
        title: 'Social Media Strategie'
      },
      sectionFive: {
        copyOne:
          'Durch die digitale Disruption wird das Netz für viele Unternehmen gerade zu einem der größten Absatzmärkte und einer der wichtigsten Kommunikationsplattformen in ihrem Portfolio – und wenn es jetzt noch nicht so ist, dann in naher Zukunft. Denn die Menschen möchten vor allem eines: Austausch. Die Corporate Website ist der erste digitale Touchpoint, mit dem sich ein Unternehmen Interessierten, potenzielle Auftraggeber:innen oder aber neuen Talenten präsentiert. Eine Corporate Website ist mittlerweile viel mehr als multifunktionale Interaktions- und Kommunikationsplattform zu begreifen. Hier sind immer digitaler werdenden Customer Journeys zur echten Differenzierung und Positionierung im Markt der Erfolgsfaktor.',
        copyTwo:
          'Wir konzipieren Ihre Corporate Website unter Berücksichtigung der Gesamt-(Digital)-Strategie und schaffen so ein ganzheitliches, bruchloses Markenerlebnis – off- wie online. Unser Team aus Entwickler: innen setzt die konzipierte Website mit einer für das Projekt passenden Technologie um.',
        title: 'Corporate Websites'
      },
      sectionSix: {
        copyOne:
          '„B2B hat seine eigenen Regeln“, mit genau diesem Satz haben sich B2B-Brands jahrzehntelang gegen besseres Wissen gewehrt. Ob Hersteller von Maschinen, Händler von Umverpackungen, Entwickler von Medizintechnik oder Agrarprodukten: Sie alle merken, dass ihr Produkt alleine heute nicht mehr reicht, um auf dem Business-to-Business-Markt große Sprünge zu machen.',
        copyTwo:
          'Mit der Digitalisierung wachsen auch viele unserer Kunden in neue Märkte. In den vergangenen Jahren hat sich beispielsweise Bilfinger von einem Bauunternehmen zu einem internationalen Engineering- und Servicekonzern gewandelt. Um die Wahrnehmung des Konzerns in der Öffentlichkeit zu erhöhen und die Leistungsvielfalt von Bilfinger zu unterstreichen, setzt das Unternehmen jetzt auf eine einheitliche Marke. Hier überarbeiteten wir Corporate Design und schufen so eine starke einheitliche Brand mit hohem Wiedererkennungswert über alle digitalen Touchpoints hinweg.',
        title: 'Brand & Strategie'
      }
    },
    internalCommunication: {
      contactBox: {
        // eslint-disable-next-line quotes
        title: "Neugierig?<br>Let's talk."
      },
      outlinks: {
        linkTwo: {
          linkText: 'SharePoint Intranet der Software AG',
          linkUrl: '/software-ag-das-intranet-neu-gedacht/',
          title: 'Nächster Fall'
        },
        title: 'Das könnte dich auch interessieren'
      },
      sectionZero: {
        copyOne:
          'Interne Kommunikation bindet Mitarbeiter emotional an das Unternehmen. Für uns heißt das: Inhalte und Formate zu entwickeln und umzusetzen, die den Mitarbeitern einen echten Mehrwert bieten.',
        copyTwo:
          'Innerhalb von Unternehmen gibt es zahlreiche Quellen für spannenden Content, aber auch die Notwendigkeit zur Kommunikation wichtiger Mitteilungen. Eine attraktive interne Kommunikation prägt die Unternehmenskultur und schärft die Markentonalität des Unternehmens.'
      },
      sectionOne: {
        copyOne:
          'Wie schafft man es, dass die Mitarbeiter Unternehmensinhalte mit Freude konsumieren und mehr erfahren als im klassischen Corporate-Newsletter?',
        copyTwo:
          'Das von uns entwickelte Video-Magazin Dentsply Sirona News - ein Intranet-TV-Format - informiert und ist zugleich unterhaltsam. Es erscheint vierteljährlich in einer Länge von ca. 10 bis 15 Minuten. Die Moderatorin führt durch die Sendung und leitet die verschiedenen Beiträge ein. Darunter aktuelle Ankündigungen, Ergebnisse einer Mitarbeiterumfrage uvm. Andere Einspieler sind Teil einer Serie und stellen besondere Leistungen von Mitarbeitern dar oder präsentieren Inhalte weiterer Dentsply Sirona Standorte, was das Gemeinschaftsgefühl des globalen Konzerns fördert.',
        copyThree:
          'Interne Kommunikation bindet Mitarbeiter emotional an das Unternehmen. Für uns heißt das: Inhalte und Formate zu entwickeln und umzusetzen, die den Mitarbeitern einen echten Mehrwert bieten.',
        copyFour:
          'Innerhalb von Unternehmen gibt es zahlreiche Quellen für spannenden Content, aber auch die Notwendigkeit zur Kommunikation wichtiger Mitteilungen. Eine attraktive interne Kommunikation prägt die Unternehmenskultur und schärft die Markentonalität des Unternehmens.',
        label: 'Internes Video-Magazin',
        title: 'Emotionale Inhalte mit Mehrwert'
      },
      sectionTwo: {
        copyOne:
          'Insbesondere wenn der CEO, der Vorstand oder die Geschäftsleitung wegweisende Informationen an die Mitarbeiter kommunizieren möchten, sind Town Halls eine Möglichkeit eine Nachricht persönlich zu übermitteln und gleichermaßen für Rückfragen offen zu sein. Bei kritischen Themen wirkt sich diese Offenheit sehr positiv aus. Town Halls finden multimedial vor Ort statt und können per Livestream weltweit übertragen und aufgezeichnet werden.',
        label: 'Town Hall Meetings',
        title: 'Die Führungsebene informiert.<br>Persönlich und in Echtzeit'
      },
      sectionThree: {
        copyOne:
          'Es ist nicht immer möglich ein Town Hall durchzuführen, oft sind Themen dazu auch nicht „groß“ genug. Gerade bei kürzeren Botschaften eignen sich zwei Formate um den Kern des Inhalts persönlich zu vermitteln: Die Videobotschaft und Interviews.',
        copyTwo:
          'Das Interview: Hier findet bewusst keine direkte Ansprache mit Blick in die Kamera statt, die Vermittlung der Botschaften passiert im Gespräch, das frei und natürlich wirkt.',
        copyThree:
          'Bei unseren Produktionen stellen unsere, im Umgang mit Führungskräften erfahrenen, Redakteure die inhaltliche Qualität sicher und schaffen mit dem Produktionsteam eine angenehme Atmosphäre.',
        copyAsideOne:
          'Der direkte Blick zum Publikum - ggf. mit der Hilfe eines Teleprompters - hilft bei der Kommunikation von klaren, vordefinierten Inhalten.',
        label: 'Videobotschaften und Interviews',
        title: 'Schnelle und authentische Informationsvermittlung'
      },
      sectionFour: {
        copyOne:
          'Beides ein alter Hut. Doch wenige Trends sind so nachhaltig und stark gewachsen wie der Podcast-Konsum. Und während sich die Podcastnutzung (mindestens einmal im Monat) in Deutschland (22%) in den letzten Jahren vervielfacht hat und erfolgreiche Formate Offline auf Tour gehen, ist Deutschland im internationalen Vergleich noch eher abgeschlagen: Schweden (36%), Spanier (40%) oder Südkoreaner (58%) zeigen, wie viel “Luft noch nach oben” ist.',
        copyTwo:
          'Verschiedenste Podcast-Formate können auf unterschiedlichste Kommunikationsziele einzahlen. Klingt strategisch? Genau hier setzen wir an. Wo kann ein Podcast bei wem, wann und was bewirken? Wir finden das richtige Podcast-Format und beantworten alle Fragen, die dazu führen einen Podcast zu veröffentlichen, der nicht dem Selbstzweck dient auf der Welle des Podcast-Trends mit zu schwimmen.',
        label: 'Podcasts',
        title: 'Content ist King und Streaming ist In'
      },
      sectionFive: {
        copyOne:
          'Sie wollen möglichst schnell ein neues Intranet für Ihre Mitarbeiter bereitstellen? Unsere White Label-Lösung basiert auf dem Open Source CMS TYPO3 und lässt sich optimal auf Ihre Bedürfnisse und Vorgaben abstimmen. Die Lösung bietet einen Funktionsumfang, der den Anforderungen von Großkonzernen und deren Mitarbeiter gerecht wird. Eine personalisierte Intranet-Lösungen als Basis für den Digital Workplace.',
        label: 'White Label Intranet',
        listItemOne: 'Basis Intranet funktional in 4 Wochen',
        listItemTwo: 'Keine Lizenzkosten',
        listItemThree: 'Komplett individualisierbar',
        listItemFour:
          'Schnittstellen zu Dokumenten-Management-Systemen und Mitarbeiterdatenbanken',
        title: 'Von 0 auf Intranet in 4 Wochen'
      },
      sectionSix: {
        copyOne:
          'Die eigenen Erfolge sichtbar machen und authentisch präsentieren. Dafür eignen sich die eigenen MitarbeiterInnen perfekt, die den Kollegen Ihre persönlichen Erfolgsgeschichten präsentieren oder den Blick auf Innovationen lenken.',
        copyTwo:
          'Nicht nur die Informationsvermittlung steht im Vordergrund, sondern auch die Wertschätzung und das Expertenwissen der Protagonisten.',
        label: 'Von Mitarbeitern für Mitarbeiter',
        title: 'Infovideos und Erfolgsgeschichten'
      },
      sectionSeven: {
        copyOne:
          'Mit dem LINGNER.WERK2, unserer Business Event Location in Heilbronn bieten wir Ihnen einerseits für die Dreharbeiten eine alternative Location zu Ihrem Unternehmen oder einem Studio an, aber vor allem als Raum für Workshops und Events. Mehr Informationen finden Sie auf unserer Webseite: <a class="link link--secondary" href="https://werk2.lingner.com/" target="_blank">werk2.lingner.com</a>.',
        label: 'Business Event Location in Heilbronn',
        title: 'LINGNER.WERK2'
      }
    }
  },
  header: {
    close: 'schliessen',
    languageNotification:
      'This page is displayed in english. In case, you would rather read in german, you could switch to',
    languageNotificationLink: 'german',
    menu: 'Menü'
  },
  homePage: {
    boutiqueTeaserOne: {
      cardCopy:
        'Wir orchestrieren nicht nur digitale Kampagnen, sondern auch mal ganze Orchester.',
      copy: 'Wir begleiten Unternehmen vom ersten „kann-ich-mir-vorstellen“ bis hin zur finalen KPI Auswertung. Zu diesem Zweck arbeiten wir Inhouse, sowie in unserem gesamten Netzwerk nur mit den Besten zusammen. Sei es vor Ort, im Netz, im Prozess, in der Organisation, im Code, im Ohr, am Set, im Feed. Ganz egal wo. Hauptsache wir sind relevant und einprägsam.',
      title: 'Die digitale B2B Boutique Agentur.'
    },
    caseSudiesTeaser: {
      title: 'Case studies and insights.',
      exampleCasesTitle: 'Noch mehr entdecken:'
    },
    contactBox: {
      title: 'Wir freuen uns über Ihren Anruf!'
    },
    customerLogos: {
      title:
        'Im Einsatz für Industrie, Mittelstand und B2B. Seit über 20 Jahren.'
    },
    mainTeaserOne: {
      copyOne:
        'Denn wir lieben es vielschichtige Themen in faszinierende und zugängliche Kommunikation zu verwandeln. Je komplexer das Thema, umso höher schlägt unser digitales Herz. Und auch wenn diese Liebe anspruchsvoll sein kann, sind wir der B2B-Kommunikation vollends verschrieben. Von der Konzeption bis zur Umsetzung. Im Übrigen schon seit 1996. Unsere Kundinnen sind B2B-Weltmarktführerinnen, deren erklärungsbedürftige Produkte auf qualifizierte und internationale Zielgruppen treffen.',
      title: 'Wir schreiben<br>Liebe mit zwei B:<br>B2B.'
    },
    mainTeaserTwo: {
      copyOne:
        'Vor über 20 Jahren gründete Stefan Lingner die Agentur mit einer klaren Vision im Blick. Unter der gemeinsamen Leitung mit Tobias Hurrle</a> entwickelt sich diese Vision der exklusiven B2B Boutique Agentur stetig weiter.',
      copyTwo:
        'Die Partner, Christian Laurin, Wojtek Misiewicz und Daniel Nill reicherten diese um zusätzliche Facetten wie Social Media, Bewegtbild, Markenbildung, Campaigning, Webentwicklung, digitale Plattformkonzeption und Latte Art an.',
      title: 'B2B.<br>Mit Leidenschaft.<br>Und aus Prinzip.'
    },
    mainTeaserThree: {
      title: 'Werde lingner.com',
      copyOne:
        'Unsere Kommunikation befindet sich im stetigen Wandel, und deshalb sind wir auch immer auf der Suche nach kreativen, wunderbaren, wandelbaren und vielseitigen Profis.',
      copyTwo:
        'Hast du Lust mit uns erst verrückte Ideen zu spinnen, sie zu diskutieren, wieder umzuwerfen und schlussendlich doch zu realisieren? Reihenfolge übrigens ohne Gewähr. Dann schau doch mal rein.',
      cta: 'Arbeiten bei lingner.com'
    },
    newsTeaser: {
      title: 'Neues bei lingner.com',
      cta: 'Zum News Archiv'
    },
    podularTeaserOne: {
      itemOneCopy:
        'Podular ist die Wortkombination aus Pod und Modular. Unsere Expertinnen formen Teams (pods) aus allen kommunikativen Disziplinen einer Full-Service-Agentur. Diese kombinieren wir immer individuell (modular) um jede kommunikative Herausforderung  zu meistern. So bleiben wir kollaborativ, dynamisch und handlungsfähig, ohne dabei unnötig große Teams zu bauen oder künstliche Hierarchien zu erschaffen.',
      itemOneTitle: 'Podulare Arbeitsweise',
      itemTwoCopy:
        'What you see is what you get. Das gilt bei uns vor allem in unseren Teams, denn als Boutique Agentur arbeiten wir in-house und im Netzwerk ausschließlich mit Experten auf hohem Niveau zusammen. Weniger Wasserfall – Mehr Wirkung.',
      itemTwoTitle: 'Spezialisten Teams auf Senior Level  ',
      itemThreeCopy:
        'Wir wollen nicht nur an, oder für eine Kundin arbeiten, sondern wir wollen wirklich etwas verändern. Und das geschieht selten durch das bloße abhaken von Marketinglisten. Das schaffen wir auch nicht, indem wir uns nach dem Briefing in unseren Elfenbeinturm zurückziehen bis weisser Rauch auf- und wir mit dem heiligen Kommunikationskonzept herabsteigen. Vielmehr wollen wir gemeinsam mit unseren Kundinnen festlegen was wir wirklich erreichen wollen, bevor wir festlegen, wie groß das Banner sein darf.',
      itemThreeTitle: 'Co-Creation Prozesse',
      title: 'Was sind die Vorteile einer Boutique Agentur?'
    }
  },
  impressionsSection: {
    title: 'Eindrücke von lingner.com',
    copyOne:
      'Großartige Ideen brauchen viel Raum zum Wachsen. In der Villa Mertz haben wir einen geschichtsträchtigen Arbeitsplatz mit viel Charme und Charakter gefunden. Einen schöneren Arbeitsplatz kann man sich kaum wünschen und deshalb sind wir jeden Tag wieder aufs Neue verzaubert von der Pracht unserer Villa.',
    copyTwo:
      'Da wir uns stetig weiterentwickeln, sind auch unsere Arbeitsplätze ständig in Bewegung. Deshalb steht im Park der Villa Mertz unser LAB, in welches man sich zum Dichten & Denken oder einfach für gemeinsame Besprechungen zurückziehen kann.',
    copyThree:
      'Eine unserer Leidenschaften: Werbespots und Livestreams gemeinsam mit unseren Kunden mutig zu konzipieren und mit viel Leidenschaft fürs Detail umzusetzen.',
    copyFour:
      'Unsere Villa Mertz wurde 2017 um einen Anbau mit fabelhafter Aussicht erweitert. Getauft haben wir den Anbau auf den Namen "LINGNER WERK1".',
    copyFive:
      'Zukunftswerkstatt. Innovation. Denkfabrik. Wir vernetzen uns gerne und möchten die Menschen, mit denen wir arbeiten, persönlich kennenlernen. Deshalb gibt es das WERK2. Hier vereinen wir alles, was ein innovativer Think Tank benötigt.'
  },
  footer: {
    backToTop: 'Zurück nach oben'
  },
  global: {
    case: 'Case',
    news: 'News',
    pages: {
      agb: {
        title: 'AGB'
      },
      career: {
        title: 'Karriere'
      },
      caseStudy: {
        dentsplySironaPrimemillProductLaunch: {
          title: 'Cerec Primemill Produktlaunch'
        }
      },
      contact: {
        title: 'Kontakt'
      },
      dataProtection: {
        title: 'Datenschutzerklärung'
      },
      home: {
        titleOne: 'Über lingner.com',
        titleTwo: 'Home',
        titleThree: 'lingner.com'
      },
      imprint: {
        title: 'Impressum'
      },
      news: {
        titleOne: 'News',
        titleTwo: 'News Archiv'
      },
      sitemap: {
        title: 'Sitemap'
      }
    },
    readNow: 'Jetzt lesen',
    workAtLingner: 'Arbeiten bei lingner.com'
  },
  imprintPage: {
    property: {
      title: 'Angaben gemäß § 5 TMG:',
      copyOne: 'lingner.com ist die Homepage von'
    },
    company: {
      title: 'LINGNER CONSULTING NEW MEDIA GMBH',
      address: 'Rosenberg 1<br>74072 Heilbronn'
    },
    contacts: {
      title: 'STANDORT JAGSTHAUSEN',
      address: 'Im Bannholz 2<br>74249 Jagsthausen',
      phoneFax: 'Telefon: 0 71 31 / 642 90 30<br>Telefax: 0 79 43 / 94 27-70'
    },
    directors: {
      title: 'Geschäftsführer',
      copyOne: 'Stefan Lingner // Tobias Hurrle',
      subTitle: 'Sitz der Gesellschaft:',
      copyTwo: 'Jagsthausen // Heilbronn',
      copyThree: 'HRB 740189, Amtsgericht Stuttgart<br>USt-IdNr. DE282050365'
    },
    legalNotice: {
      title: 'Rechtshinweis',
      copyOne:
        '&copy;	Copyright LINGNER CONSULTING NEW MEDIA GMBH<br>Alle Rechte vorbehalten. Nachdruck, Aufnahme in Online-Dienste, Internet und Vervielfältigung auf Datenträger gerne nach vorheriger Zustimmung von LINGNER.',
      copyTwo:
        'Diese Website wurde mit größtmöglicher Sorgfalt zusammengestellt. Trotzdem können wir für die Fehlerfreiheit und Genauigkeit der enthaltenen Informationen nicht garantieren. Wir schließen jegliche Haftung für Schäden, die direkt oder indirekt aus der Benutzung dieser Website entstehen, aus, soweit diese nicht auf Vorsatz oder grober Fahrlässigkeit beruhen.'
    },
    linksLiability: {
      title: 'Haftung für Links',
      copyOne:
        'Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.'
    },
    contentLiability: {
      title: 'Haftung für Inhalte',
      copyOne:
        'Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.'
    }
  },
  jobPage: {
    bottomCta: 'Jetzt bewerben',
    backToCategory: 'Weitere offene Stellen in der'
  },
  jobCategoryPage: {
    contactBox: {
      title: 'Ich freue mich, von dir zu hören.'
    },
    featureBox: {
      title: 'Warum ist meine Stelle nicht dabei?',
      copyOne:
        'Sehr gute Frage. Das könnte daran liegen, dass wir vielleicht noch garnicht wissen, dass wir jemanden wie dich brauchen. Wenn du also weißt, was wir brauchen, dann schick uns einfach deinen Lebenslauf per Mail oder ruf uns ganz oldschool an.',
      cta: 'Initiativbewerbung'
    },
    subText: {
      consulting:
        'Beratung klingt genau nach deinem Ding? Dann schau dir <a class="link link--primary link--secondary" href="/karriere/beratung"><strong>hier</strong></a> an, was dich als Berater:in bei uns erwartet.',
      creation:
        'Kreation klingt genau nach deinem Ding? Dann schau dir <a class="link link--primary link--secondary" href="/karriere/kreation"><strong>hier</strong></a> an, was dich in der Kreation bei uns erwartet.',
      development:
        'Entwicklung klingt genau nach deinem Ding? Dann schau dir <a class="link link--primary link--secondary" href="/karriere/entwicklung"><strong>hier</strong></a> an, was dich als Entwickler:in bei uns erwartet.'
    },
    jobsList: {
      title: 'Offene Stellen'
    },
    bottomCta: 'Mehr über lingner.com'
  },
  navigationMenu: {
    blockOne: {
      copyOne:
        'Hier erfährst du (fast) alles, was es über unsere Agentur zu wissen gibt.',
      copyTwo:
        'Zum Beispiel: Warum wir <a id="scroll-to-wir-schreiben-liebe-mit-zwei-b-b2b" class="link link--tertiary link--has-underline scroll-to" href="/#wir-schreiben-liebe-mit-zwei-b-b2b">Liebe mit 2 „B“</a> schreiben. Die <a id="scroll-to-b2b-mit-leidenschaft-und-aus-prinzip" class="link link--tertiary link--has-underline scroll-to" href="/#b2b-mit-leidenschaft-und-aus-prinzip">Geschichte</a> hinter unserer Agentur und was eigentlich die <a id="scroll-to-was-sind-die-vorteile-einer-boutique-agentur" class="link link--tertiary link--has-underline scroll-to" href="/#was-sind-die-vorteile-einer-boutique-agentur">Vorteile einer Boutique Agentur</a> sind? Wer möchte darf auch gerne in unseren <a id="scroll-to-case-studies-and-insights" class="link link--tertiary link--has-underline scroll-to" href="/#case-studies-and-insights">Case studies und Insights</a> stöbern, erforschen was es <a id="scroll-to-neues-bei-lingnercom" class="link link--tertiary link--has-underline scroll-to" href="/#neues-bei-lingnercom">Neues bei lingner.com</a> gibt, oder einfach direkt <a id="scroll-to-und-so-erreichst-du-uns" class="link link--tertiary link--has-underline scroll-to" href="/#und-so-erreichst-du-uns">Kontakt</a> aufnehmen.',
      title: 'Über<br>Lingner.com'
    },
    blockTwo: {
      copyOne:
        'Hier erfährst du alles, was es über das Arbeiten bei uns zu wissen gibt.',
      copyTwo:
        'Zum Beispiel: Wieso gelb für uns auch gleichzeitig <a id="scroll-to-team-gelb" class="link link--tertiary link--has-underline scroll-to" href="/karriere#team-gelb">bunt</a> bedeutet. Ergreife die <a id="scroll-to-irgendwas-mit-medien" class="link link--tertiary link--has-underline scroll-to" href="/karriere#irgendwas-mit-medien">Initiative</a> oder erfahre welche <a id="scroll-to-offene-stellen" class="link link--tertiary link--has-underline scroll-to" href="/karriere#offene-stellen">Positionen</a> hier im Moment besonders gefragt sind. Und wer noch immer einen Grund sucht bei uns einzusteigen, für den haben wir sogar <a id="scroll-to-und-warum-lingner" class="link link--tertiary link--has-underline scroll-to" href="/karriere#und-warum-lingner">14 gute Gründe</a> vorbereitet. Cool? Warum dann nicht direkt <a id="scroll-to-contact-box" class="link link--tertiary link--has-underline scroll-to" href="/karriere#contact-box">Kontakt</a> aufnehmen?',
      title: 'Arbeiten bei<br>Lingner.com'
    },
    close: 'Menü schliessen'
  },
  newsPage: {
    emptyTitle: 'Neues von lingner.com'
  },
  notFoundPage: {
    titleOne:
      'Was ist gelb und kann nicht schwimmen?<br>Richtig. Ein Bagger.<br>Der hat ja auch nur einen Arm.',
    titleTwo: 'Jetzt aber zurück zur',
    link: 'Startseite'
  },
  team: {
    stefanLingner: {
      role: 'Geschäftsführung',
      url: '#stefanlingner'
    },
    tobiasHurrle: {
      role: 'Geschäftsführung'
    },
    fabianBorst: {
      role: 'Partner'
    },
    christianLaurin: {
      role: 'Partner'
    },
    wojtekMisiewicz: {
      role: 'Partner'
    },
    danielNill: {
      role: 'Partner'
    },
    carolaRogge: {
      role: 'People & Culture'
    }
  },
  url: {
    agb: '/assets/static/lin_18_AGB_20180724.pdf',
    career: '/karriere/',
    caseStudy: {
      dentsplySironaPrimemillProductLaunch:
        '/dentsply-sirona-primemill-product-launch/'
    },
    dataProtection: '/datenschutz/',
    home: '/',
    imprint: '/impressum/',
    news: '/news/',
    tiktok: 'https://www.tiktok.com/@lingner.com?lang=de-DE',
    sitemap: '/sitemap/',
    gwa: 'https://www.gwa.de/'
  },
  sitemap: {
    heading: 'Sitemap von lingner.com',
    home: {
      pageTitle: 'Home',
      url: '/'
    },
    news: {
      pageTitle: 'News',
      url: '/news/'
    },
    imprint: {
      pageTitle: 'Impressum',
      url: '/impressum/'
    },
    dataProtection: {
      pageTitle: 'Datenschutz',
      url: '/datenschutz/'
    },
    agb: {
      pageTitle: 'AGB',
      url: '/assets/static/lin_18_AGB_20180724.pdf'
    },
    career: {
      pageTitle: 'Karriere',
      url: '/karriere/'
    },
    careerAdministration: {
      pageTitle: 'Administration',
      url: '/karriere/administration/'
    },
    careerCreation: {
      pageTitle: 'Kreation',
      url: '/karriere/kreation/'
    },
    careerDevelopment: {
      pageTitle: 'Entwicklung',
      url: '/karriere/entwicklung/'
    },
    careerConsultation: {
      pageTitle: 'Beratung',
      url: '/karriere/beratung/'
    },
    careerAugmentedReality: {
      pageTitle: 'Erweiterte Realität',
      url: '/karriere/erweiterterealitaet/'
    }
  }
};
