const getValidatedFields = form => {
  return form.querySelectorAll('[name][required]');
};

const BACKEND_FORM_URL = `${
  process.env.PUBLIC_BACKEND_BASE_URL || ''
}/backend/new-social-registration.php`;

class NewSocialForm {
  static initAll() {
    document.querySelectorAll('.js-new-social-form').forEach(form => {
      new NewSocialForm(form);
    });
  }

  constructor(form) {
    this.form = form;

    console.log('[ContactForm]', 'Initializing form', form);

    form
      ?.querySelector('[type=submit]')
      ?.addEventListener('click', this.handleSubmit.bind(this));

    form?.addEventListener('submit', event => event.preventDefault());

    getValidatedFields(this.form).forEach(el => {
      const errorElement = document.createElement('span');
      errorElement.classList.add(
        'form__field-error',
        'form-field-error--hidden'
      );
      el.closest('.form__field')?.appendChild(errorElement);

      el.addEventListener('blur', () => this.checkFieldValidity(el));
      el.addEventListener('keyup', () =>
        setTimeout(() => {
          this.checkFieldValidity(el);
        }, 300)
      );
      el.addEventListener('change', () => this.checkFieldValidity(el));
      window.addEventListener('resize', () => {
        if (!el.classList.contains('form__field-error--hidden')) {
          errorElement.style.maxHeight = errorElement.scrollHeight + 'px';
        }
      });
    });
  }

  checkFieldValidity(element) {
    const formField = element.closest('.form__field');
    const isValid = element.checkValidity();

    if (!formField) {
      return;
    }

    const errorElement = formField.querySelector('.form__field-error');

    if (isValid) {
      errorElement.style.maxHeight = '';
      errorElement.classList.add('form__field-error--hidden');

      setTimeout(() => {
        errorElement.textContent = '';
      }, 150);
    } else {
      errorElement.textContent = element.validationMessage;

      setTimeout(() => {
        errorElement.style.maxHeight = errorElement.scrollHeight + 'px';
        errorElement.classList.remove('form__field-error--hidden');
      }, 150);
    }

    formField.classList.toggle('form__field--invalid', isValid === false);

    return isValid;
  }

  handleSubmit(event) {
    event.preventDefault();

    let isFormValid = true;

    console.log('handle submit');

    getValidatedFields(this.form).forEach(el => {
      if (!this.checkFieldValidity(el)) {
        isFormValid = false;
      }
    });

    if (!isFormValid) {
      const submitMessage = this.form.querySelector('.form__submit-message');

      submitMessage.classList.remove('form__submit-message--success');
      submitMessage.textContent = submitMessage?.getAttribute('data-error');
      submitMessage.classList.remove('form__submit-message--hidden');

      return;
    }

    const formData = new FormData(this.form);

    console.log(
      '[ContactForm]',
      `Submitting form to ${BACKEND_FORM_URL} with following fields: `,
      Array.from(formData)
    );

    fetch(BACKEND_FORM_URL, {
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: new URLSearchParams(formData).toString()
    })
      .then(async response => {
        const submitMessage = this.form.querySelector('.form__submit-message');
        const jsonResponse = await response.json();

        if (response.ok && jsonResponse.message) {
          submitMessage.classList.add('form__submit-message--success');
          submitMessage.textContent = jsonResponse.message;
          submitMessage.classList.remove('form__submit-message--hidden');
        } else {
          throw new Error('Failed to submit form');
        }
      })
      .catch(() => {
        const submitMessage = this.form.querySelector('.form__submit-message');

        submitMessage.classList.remove('form__submit-message--success');
        submitMessage.textContent = submitMessage?.getAttribute('data-failure');
        submitMessage.classList.remove('form__submit-message--hidden');
      });
  }
}

export default NewSocialForm;
