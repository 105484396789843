/* eslint-disable quotes */
module.exports = {
  btnChat: {
    chatWithTobias: 'Chat with Tobias',
    contactWojtek: 'Contact Wojtek'
  },
  careerPage: {
    contactBox: {
      title: 'We are looking forward to your call.'
    },
    mainTeaserOne: {
      copyOne: `You really want to make a difference and become part of an extraordinary team? Then you have come to the right place. Because at Lingner, we're not just yellow, we're colourful. Join up with us and do anything but the usual.`,
      title: 'Team Yellow.'
    },
    mainTeaserTwo: {
      copyOne: `Sure, anyone can do that, but you're not satisfied with the standard. That's good. Neither are we. That's why we're looking for extraordinary talents, creative minds, and versatile professionals like you.`,
      copyTwo: `But it's not just about your hard or soft skills, your CV or how many awards you have in the bag. Much more important for us is the right attitude. Your passion, your team spirit, the joy of learning, the will to make a difference, the fun of making complex B2B topics seem easy, and an innate unquenchable curiosity. Do you see your strengths more in programming, layouts, writing spots, conceptualising, animating, organising, presenting, advising, thinking strategically, or are you one of those who simply don't fit in any box? No matter. We think all that is cool and that it belongs here with us.`,
      cta: 'Apply now',
      title: 'Something about media...'
    },
    mainTeaserThree: {
      copyOne: `Our operating language is German. If you have little knowledge of German so far, but still think you could be a perfect fit for our team, feel free to send us your CV.`,
      title: 'Open positions.'
    },
    mainTeaserFour: {
      slider: {
        slide0: {
          aside: {
            copyOne: `Yeah, alright. 13 good reasons would have sounded more epic somehow. But in the end, it turned out to be more than 13. So we'll just leave it at that.`,
            title: '14 good reasons.'
          }
        },
        slide1: {
          aside: {
            copyOne: `Personal freedom and respect are written in bold capital letters with us. Egomaniacs and creative bosses, on the other hand, can go ahead and stay at home or in the 90s. Anyone who dispenses with airs and graces can expect extraordinary colleagues from all over the world. Sometimes we feel like a little yellow family. That's why young talent is typical for us, and we know how to incorporate it into everyday work or vice versa.`,
            title: '#1 Become lingner.com.'
          }
        },
        slide2: {
          aside: {
            copyOne: `We're proud to work in one of the most beautiful and traditional buildings in Heilbronn: The classicist Villa Mertz. Located directly on the Neckar promenade, it has its own park, which we use in summer not only for barbecuing but also for taking a breath of creative air. The train station is also in the immediate vicinity, in addition to an outdoor swimming pool, the pedestrian zone, and the Heilbronn gastronomic mile.`,
            title: '#2 Yes, in a villa.'
          }
        },
        slide3: {
          aside: {
            copyOne: `Our B2B clients are all united in that their products require explanations and meet a demanding and international audience. This makes our hearts beat faster because we love to turn complicated topics into fascinatingly accessible communication. For example, we make the biotech group Qiagen social media-oriented, design and implement Enterprise CMS (Software AG), develop and operate Internet and intranet applications for Implenia, live streams for the corona policy of the Federal Republic of Germany, or develop an employer brand for Leadec.`,
            title: '#3 Complicated.'
          }
        },
        slide4: {
          aside: {
            copyOne: `As diverse as our clients are, the challenges that await you are just as exciting. However, something new requires not only the courage of the client, but also the knowledge and motivation of all colleagues. That's why we work in interdisciplinary teams, are not afraid of new things, and are constantly developing. Like Pikachu. Have a special strength or skill and even more potential? Perfect. Let's work together to make you even better.`,
            title: '#4 Building strengths.'
          }
        },
        slide5: {
          aside: {
            copyOne: `It's not always easy, but it's always important to us. Because without trust in one's own abilities, ideas, competences and potential, it's simply impossible to leave the beaten path and create something new. We also dare to be self-critical and to question ideas openly to develop them further. This "courage to trust" is the basis of our culture.`,
            title: '#5 The courage to trust.'
          }
        },
        slide6: {
          aside: {
            copyOne: `We are a management consultancy and creative agency in one. Though for us, this is not a contradiction, but rather an identity. Because we combine corporate strategy with creative ease, charts with brand images, tradition with the digital future, B2B with LOL, Wall Street with street style, and are not looking for the easiest way, but rather the one that leads us to our goal.`,
            title: '#6 Two worlds.'
          }
        },
        slide7: {
          aside: {
            copyOne: `Traditional forms of organisation did not live up to our demands and that's why we have opted for a more agile and innovative approach: the Podular Organization. Podular is the merging of 'pod' and 'modular'. We combine our expert teams (pods) from all the communicative disciplines of a full-service agency individually (modularly) in order to master every communicative challenge for our clients. By doing so, we remain collaborative, dynamic and capable of action, without building unnecessarily large teams or creating artificial hierarchies. The word they're looking for is pod-ular. Po-du-lar.`,
            title: '#7 Podular.'
          }
        },
        slide8: {
          aside: {
            copyOne: `So that your intervertebral discs also feel comfortable in a mobile office, we have everything you need delivered to your home. That includes the height-adjustable desk, an ergonomic office chair and a wiggle board to keep your balance.`,
            title: '#8 Working from home.'
          }
        },
        slide9: {
          aside: {
            copyOne: `We don't just offer only the obligatory fruit basket, an Italian coffee machine, the foosball table, and the beverage flat rate. You also have the possibility of 30 days of vacation, a mobile phone contract, travel allowance, training, etc. Looking for something full time? Good, so are we. That's why open-ended employment contracts are the rule with us. Flexible working hours and part-time models create space for young people, family, friends, hobbies or your own projects. And even when it comes to our success, we love to keep things transparent. See #11.`,
            title: '#9 Benefits.'
          }
        },
        slide10: {
          aside: {
            copyOne: `Space for development is more than a mental need. It's also physical. For this purpose, not just the charming headquarters in the centre of Heilbronn serves as a possible workplace. We also offer you additional space for creative development. For example, with the award-winning WERK1 attached to the villa, the LINGNER.LAB located in the park, and our WERK2 in an urban studio look.`,
            title: '#10 Free spaces.'
          }
        },
        slide11: {
          aside: {
            copyOne: `We feel nothing but love for the park belonging to the villa. There we spend the lunch break under chestnut trees, a creative pause for reflection on the gravel circular path and the after-work beer tastes particularly good while strolling as the squirrels gather around the fountain. Relaxation begins at the villa front door.`,
            title: '#11 Green at heart.'
          }
        },
        slide12: {
          aside: {
            copyOne: `The digital LINGNER.GOLD course shows our employees at all times and transparently how the company is doing economically. In addition, it's even able to generate forecasts from the accounting data. Each successful project has a direct positive effect on the LINGNER.GOLD course. This is stated in euros and paid out at the beginning of the following year. Because if the company is doing well, then we pass it on. To those who made this possible in the first place: the entire team. It's a golden rule for us.`,
            title: '#12 Show me the numbers, please.'
          }
        },
        slide13: {
          aside: {
            copyOne: `Formerly often called Heil-Bronx, the city on the Neckar has left behind its rough image. Thanks to numerous major projects such as the German Federal Garden Show, the experimenta, or the Bildungscampus, both the wine and the economy are growing strong here. Four universities have settled here. These include the innovative Coding School 42 and TUM. As a result, education and urban flair belong to the university city of Heilbronn like sauce on the spätzle. Investments aren't just made in the economy, but also in the future. For example, with free and available kindergarten spots and the new Neckarbogen green district.`,
            title: '#13 Heilbronn.'
          }
        },
        slide14: {
          aside: {
            copyOne: `Think coffee is not a reason to work with us? You're absolutely right. But also maybe completely wrong. Because coffee lovers will find a lot to love with us. Not just the Italian coffee machine, the selected beans, but also the lively discussions about their mixing and preparation will make your heart beat harder and above all faster. Some of our employees use milk to conjure up more powerful illusions in the coffee than Houdini could begin to dream of.`,
            title: '#14 Black gold.'
          }
        }
      },
      title: 'And why Lingner?'
    }
  },
  caseStudyPage: {
    customer: 'Client',
    service: 'Our services',
    tagline: 'Case',
    dentsplySironaPrimeprintVirtualProduction: {
      contactBox: {
        // eslint-disable-next-line quotes
        title: "Curious?<br>Let's talk."
      },
      highlights: {
        customer: 'Dentsply Sirona',
        highlightOne: 'Virtual Production'
      },
      mainHero: {
        subTitle:
          'The Magic Inside! A virtual production provides unprecedented insights into the interior of the 3D printer Primeprint'
      },
      outlinks: {
        linkTwo: {
          linkText: 'Dentsply Sirona Primemill Product Launch',
          linkUrl: 'en/dentsply-sirona-primemill-product-launch/',
          title: 'Next case'
        },
        title: 'You might also be interested in'
      },
      sectionOne: {
        copyOne:
          'Establishing a new 3D printing system on the dental market is one thing. Communicating it in such a way that dentists and dental technicians are not only introduced to the new technology, but are also completely enthusiastic about it, is another.',
        copyTwo: `"We are faced with the challenge that we have put so much brainpower into the whole product that sometimes we don't know exactly how to actually explain it," Johannes Krug, Global Head of Manufacturing Products at Dentsply Sirona, sums it up.`,
        copyThree: `Especially, when the magic of the 3D printer happens almost invisibly inside two machines. And it's exactly these tasks that we love.`,
        titleOne: 'Challenge'
      },
      sectionTwo: {
        copyOne:
          'To explain this novel printing process and the different phases, we were left with only one option: we had to tell the story inside the 3D printer itself. And we had to do it in a new way that would set standards and at the same time be a perfect fit for this high-tech product.',
        titleOne: 'Idea'
      },
      sectionThree: {
        copyOne:
          'At the heart of the communication is the film: "The Magic Inside - Primeprint 3D Printing Solution". Let the charming protagonists Dr. Isabelle Quinn (Annabelle Mandeng) and Daniel Prime (Seumas Sargent) take you on a magical journey, see how the real world blurs with the virtual world and experience the wonderous world of dental technology with them. In the following project film, you can learn everything about the production and look over our shoulder on the set.',
        copyTwo:
          'The conception process deliberately resulted in a virtual production, which gave us the greatest freedom of design on the one hand and production reliability on the other.',
        copyThree:
          'The virtual worlds (levels) produced in advance were, for example, the museum scene, the portal and the digital twin of the 3D printer, including functions and processes. In the LED studio, the protagonists were able to immerse themselves live in the levels on the day of filming and became one with the virtual environment, which was complemented by a real scenery to make the illusion perfect.',
        copyFour:
          'And the good thing about the digital twin: it can be used not only in this specific film but is part of various phases of the marketing communication.',
        titleOne: 'Creation',
        titleTwo: 'Virtual production and digital twin'
      },
      sectionFour: {
        copyOne:
          'For the film "The Magic Inside - Primeprint 3D Printing Solution" we have been awarded a silver dolphin at Cannes and are again winners of the Cannes Corporate Media & TV Awards. We are proud of the recognition for such a special project.',
        copyTwo:
          'Thanks to our partners during the production and the large, colorful and dedicated team that made the project such a success.',
        copyThree:
          'Auftraggeber: Dentsply Sirona<br>Creation and production: LINGNER.COM<br>Actress: Annabelle Mandeng<br>Actor: Seumas Sargent<br>Idea / Concept: Tobias Hurrle, Raoul Baumgärtner, Fabian Borst<br>Screenplay: Fabian Borst, Raoul Baumgärtner, Rupert Maitland<br>Director: Fabian Borst: Assistant Director: Natalie Kölbl<br>Director of Photography: Chathura Panangala<br>Trinity Steadicam Operator: Janis Willbold<br>1.AC Steadicam: Marvin Puscher<br>Camera Crane Operator:  Kevin Buse<br>1.AC Camera Crane: Lea Sarau<br>Focus Puller Camera Crane: Bastian Blum<br>Set sound engineer: Alexander Theodossiadis<br>Sound engineer: Marcus Ritzel<br>Production Manager: Christian Laurin<br>Key Account: Wojtek Misiewicz<br>Stage and set construction: Tom Schilling, Robert Scherzinger, Philipp Locher<br>Head lighting technician: Manuel Dolderer<br>Lighting technician: Jana Radomski<br>Best Boy: Sören Gerhard<br>LEDCave Studio Manager:  Thilo Strack<br>LEDCave Project Manager: Finn Krieger<br>LEDCave Technical Manager:  Lukas Rösch<br>LEDCave Account Management: Steffen Lang<br>Set Manager: Frank Junghahn<br>Virtual Design & Unreal Specialist: Nikolaus Schlüter, Tobias Huber, Alexander Söll<br>Unreal Optimizer: Timo Müssig<br>Hair & Makeup: Charlie Ksiazek<br>Costume: Saskia Müller<br>Data Wrangler: Georg Solf<br>Media operator: Sven Schaller<br>Choreographer: Joshua Taake<br>On Set Feelgood Management: David Zimmermann<br>Storyboard & Visuals: Frank Richter, Nick Denecke<br>Photos and Making of Video: Julian Meinhardt<br>Editing: Natalie Kölbl<br>Color Grading: Natalie Kölbl<br>Motion Design & Graphics: Erwin Juliestersen Kopeng<br>Film music: Leonard Küßner, Musicbed<br>Sound design and mixing: Frederik Pfeiffer<br>Social Media Editor: Sascha Seidel',
        titleOne: 'Awards',
        titleTwo: 'Behind the scenes',
        titleThree: 'Credits'
      }
    },
    dentsplySironaDS24: {
      contactBox: {
        // eslint-disable-next-line quotes
        title: "Curious?<br>Let's talk."
      },
      highlights: {
        customer: 'Dentsply Sirona',
        highlightOne: 'Idea and concept',
        highlightTwo: 'Livestream production',
        highlightThree: 'Internal communication'
      },
      mainHero: {
        subTitle:
          '...then LINGNER.COM creates an extraordinary 24h livestream experiment: #ds24hours'
      },
      outlinks: {
        linkTwo: {
          linkText: 'New Software AG Intranet based on SharePoint',
          linkUrl: '/en/software-ag-intranet-reimagined/',
          title: 'Next case'
        },
        title: 'You might also be interested in'
      },
      sectionOne: {
        copyOne:
          'The "lived" culture could not keep pace with the development of the transformation process. According to an employee survey, the commitment of the employees was too low at the time. The challenge now was to find a way to counteract this development positively and to communicate the core messages globally and locally through dialogue.',
        titleOne: 'The challenge.'
      },
      sectionTwo: {
        copyOne:
          'Implemented in a total of 24 hours andwith a few breaks within just 31 hours, 95 locations were to be reached in one-hour live sessions. In the dialogue in picture and sound. Depending on the country or continent, the live transmission should be supplemented by simultaneous interpreters.',
        slider: {
          slideOne: {
            aside: {
              copyOne:
                'We defined personas and then developed a multi-level model in order to be able to communicate in a targeted manner both to the CEREC believers and to the considerers.'
            }
          },
          slideTwo: {
            aside: {
              copyOne: 'Real dialogue with employees.'
            }
          },
          slideThree: {
            aside: {
              copyOne: 'Live in 10 languages.'
            }
          },
          titleOne: 'Insights.'
        },
        titleOne: 'Strategy.'
      },
      sectionThree: {
        copyOne:
          'Live for 24 hours, in 24 one-hour town hall meetings, the five-member management team spoke to the employees. Each session was specifically tailored to each region.',
        imageCaptionOne: 'Branded studio in the workspace',
        titleOne: 'Creation.'
      },
      sectionFour: {
        copyOne:
          'DS24hours exceeded the expectations of all involved and was a complete success. Almost all employees worldwide were reached and entered into an active dialogue, so that not only purpose, mission and the corporate culture were communicated more strongly, but also the commitment of the workforce was sustainably strengthened.',
        copyTwo: '',
        copyThree:
          'Dentsply Sirona and LINGNER.COM have been awarded numerous national and international awards for the DS24hours project. We are proud of this recognition for such a special project. For some awards, the presentation is still pending.',
        numberHighlightsOne: {
          itemOne: {
            number: '13.000',
            label: 'Employees reached'
          },
          itemTwo: {
            number: '650',
            label: 'Submitted questions'
          },
          itemThree: {
            number: '9.000',
            label: 'Actively involved in the live event'
          }
        },
        titleOne: 'The results.',
        titleTwo: 'Awards.'
      }
    },
    dentsplySironaPrimemillLaunch: {
      contactBox: {
        // eslint-disable-next-line quotes
        title: "Curious?<br>Let's talk."
      },
      highlights: {
        customer: 'Dentsply Sirona',
        highlightOne: 'Campaign Film',
        highlightTwo: 'Social Media Campaign',
        highlightThree: 'Print Camapaign',
        highlightFour: 'Launch Event'
      },
      mainHero: {
        subTitle:
          '...then we orchestrate the international launch not only in the usual big and visually stunning way, but also emotionally and surprisingly honestly.'
      },
      outlinks: {
        linkTwo: {
          linkText: '24h Stream',
          linkUrl: '/dentsply-sirona-ds24/',
          title: 'Next Case'
        },
        title: 'You might also be interested in'
      },
      sectionOne: {
        copyOne:
          'While users are enthusiastic throughout, a broad group of non-users remains skeptical. Prejudices circulate, but so do real barriers to entry. How do you reach a target group that has already emotionally turned away from the brand years ago?',
        titleOne:
          'How do you reach a target group that has already emotionally turned away from the brand years ago?'
      },
      sectionTwo: {
        copyOne:
          'We defined personas and then developed a multi-level model to communicate specifically to both CEREC believers and considerers.',
        slider: {
          slideOne: {
            aside: {
              copyOne:
                'We defined personas and then developed a multi-level model to communicate specifically to both CEREC believers and considerers.'
            }
          },
          titleOne: 'Insights'
        },
        titleOne:
          'First, we analyzed hundreds of data sets to better understand the brand and target group.'
      },
      sectionThree: {
        copyOne:
          'In the campaign film, "Now is the time," we talk about the worries and fears we all know about significant decision moments in our lives, but also about the tingles and positive energy that come with starting a new chapter.',
        copyTwo:
          'The campaign film is extended through an extensive social media campaign, as well as print ads and more in-depth material for events. This new and more honest communication gives the considerers a reason to rethink their defensive attitude and engage in CEREC again.',
        copyThree: `The product film "Excellence made easy", which takes center stage at the event, not only impressively presents the product's features but also brims with pride and enthusiasm: Daniel Prime's Vivaldi concert with CEREC Primemill as star soloist.`,
        imageCaptionOne: '',
        imageCaptionTwo: '',
        slider: {
          slideOne: {
            copyOne: 'Don Casey, CEO at Dentsply Sirona',
            titleOne: '"Fireworks that absolutely blew our customers away."'
          },
          titleOne: 'What our customer says'
        },
        titleOne: `Addressing & overcoming the considerers' fears`,
        titleTwo: 'Pure enthusiasm at the launch event'
      },
      sectionFour: {
        copyOne:
          'A tightly interlinked campaign, which has carried away sales, key opinion leaders and customers from the product revealing to the social media campaign, with an emotional response rarely seen in the dental industry.',
        titleOne:
          'More than 3,000 CEREC Primemill units sold in a challenging year 2020 with the Covid pandemic. '
      }
    },
    weinigExperience: {},
    softwareAg: {
      mainHero: {
        subTitle: `Connect, Collaborate, Celebrate – Welcome to the future of Intranet at Software AG!`
      },
      highlights: {
        customer: 'Software AG',
        highlightOne: 'Social intranet',
        highlightTwo: 'Launch campaign for new intranet'
      },
      outlinks: {
        linkTwo: {
          linkText: '24h Stream',
          linkUrl: '/en/dentsply-sirona-ds24/',
          title: 'Next case'
        },
        title: 'You might also be interested in'
      },
      contactBox: {
        title: `Curious?<br>Let's talk.`
      },
      sectionOne: {
        copyOne:
          "The previous Intranet at Software AG was vast but outdated. With the clock ticking on their CMS license, we felt the pressure to renew the digital workspace. Piles of internal newsletters and scattered local 'shadow' intranets urged us to rethink strategy and declutter the digital communication space.",
        titleOne: 'The Challenge'
      },
      sectionTwo: {
        copyOne:
          "A global Social Intranet to connect Software AG's talents. <br>" +
          'Beyond just a workplace tool, the renewed Intranet fosters active collaboration, tailors’ content per user, and invites everyone into an integrated conversation. Picture the Management Board interacting directly with all employees through dedicated blogs.' +
          '<br><br>' +
          '<strong>Leveraging Microsoft SharePoint</strong><br>' +
          'We had the licenses and some experience. SharePoint was our bridge to transform this vision into reality. The challenge? Filling it up on time. Our solution: a phased approach. Starting with essentials and gradually enriching with feedback-driven content.',
        titleOne: 'Our Vision'
      },
      sectionThree: {
        copyOne:
          "We initiated deep-dive interviews, formed a team of experts from LINGNER.COM and a variety of Software AG departments, and set an ambitious launch at Software AG's premier internal Sales Event. Our goal? Not merely to copy old content, but to curate, refine, and present only what is relevant.<br>" +
          '<br>' +
          "Using SharePoint's built-in capabilities, such as Viva Engage (previously Yammer), our social intranet flourishes. We see lively photo-sharing communities emerge and an active forum on the Social Intranet itself.",
        copyTwo:
          "Bye-bye overwhelming newsletters. Hello targeted news and events via SharePoint's personalization features. Our communication strategy? Ignite curiosity with teasers, showcase new features, and celebrate the ever-evolving nature of our Intranet – a reflection of our dynamic workforce.<br>" +
          '<br>' +
          'The big reveal included guided video tours by our employees, demystifying the new platform for all.',
        titleOne: 'Implementation',
        slider: {
          slideOne: {
            titleOne:
              '"A triumphant launch, thanks to the stellar efforts by LINGNER.COM. The guided tour made the transition smooth. Here is to collaboration and innovation!"',
            copyOne: 'Dr. Astrid Kasper, SVP Global Corporate Communications'
          },
          slideTwo: {
            titleOne:
              '"Teaming up with LINGNER.COM was a game-changer. Our global team now enjoys a cutting-edge collaboration hub."',
            copyOne: 'Dr. Elke Frank, CHRO at Software AG'
          },
          titleOne: 'What our customer says:'
        }
      }
    }
  },
  contactSection: {
    title: 'How to reach us.',
    contacts: {
      general: {
        title: 'For all your concerns.',
        phone: '07131 - 642 90 30',
        phoneLink: 'tel:+49071316429030',
        email: 'info@lingner.com'
      },
      recruiting: {
        title: 'For job applications. ',
        nameRole: 'Carola Rogge<br>People & Culture',
        phone: '07131 - 642 90 47',
        phoneLink: 'tel:+49071316429047',
        email: 'jobs@lingner.com'
      }
    },
    locations: {
      title: 'Our locations',
      villaMertz: {
        title: 'Villa Mertz',
        description:
          'Our headquarters. The mothership. The base. The nucleus of our creative spirit. Built in the midst of greenery and close to the water, the neoclassical villa offers us both a home and inspiration.',
        address:
          'Rosenberg 1<br>74072 Heilbronn<br><a class="link link--tertiary link--has-underline link--has-underline" href="/assets/static/way-to-lingner-villa.pdf" target="_blank">Directions</a>'
      },
      inverveStudios: {
        title:
          '<a class="link link--primary" href="https://inverve.io" target="_blank">The inverve studios</a>',
        description:
          'Our production house for your digital productions. AR, VR, MR, or live event? No matter what you have in mind, our 100m² stage in combination with the LED wall can make it happen.',
        address:
          'Hafenstraße 11 - 13<br>74076 Heilbronn<br><a class="link link--tertiary link--has-underline link--has-underline" href="/assets/static/way-to-inverve-studios.pdf" target="_blank">Directions</a>'
      },
      werk2: {
        title:
          '<a class="link link--primary" href="https://werk2.lingner.com" target="_blank">The LINGNER.WERK2</a>',
        description:
          'The business event location in Heilbronn is a conference room for workshops, conferences, meetings, and seminars. Only with a pinball machine.',
        address:
          'Salzstraße 42<br>74076 Heilbronn<br><a class="link link--tertiary link--has-underline link--has-underline" href="/assets/static/way-to-lingner-werk2.pdf" target="_blank">Directions</a>'
      }
    }
  },
  dataProtectionPage: {
    sectionOne: {
      title: '1. Responsibilities and contact details',
      copyOne:
        'This data protection information applies to data processing by:',
      copyTwo:
        'Person responsible: Stefan Lingner and Tobias Hurrle (Managing Director) LINGNER CONSULTING NEW MEDIA GMBH (hereinafter: Lingner), Rosenberg 1, 74072 Heilbronn, Germany.',
      phoneFax: 'Phone: +49 71 31 / 642 90 30<br>Fax: +49 79 43 / 94 27-70',
      copyThree:
        'The external operational data protection supervisor of LINGNER.COM can be contacted under the address Ferdinand-Braun-Straße 26, 74074 Heilbronn, Germany, or <a class="link link--primary link--has-underline" href="mailto:schoener@adrem-gmbh.de">schoener@adrem-gmbh.de</a>.'
    },
    sectionTwo: {
      title:
        '2. Collection and storage of personal data as well as type and purpose of their use',
      subparagraphA: {
        title: 'a) When visiting the website',
        copyOne:
          'When you visit our website www.lingner.com, the browser used on your terminal device automatically sends information to the server of our website. This information is temporarily stored in a so-called log file.',
        copyTwo:
          'The following information is collected without your intervention and stored up to automated deletion:',
        listOne: {
          itemOne: 'IP address of the requesting computer,',
          itemTwo: 'Date and time of access,',
          itemThree: 'Name and URL of the accessed file,',
          itemFour: 'website from which the access was made (referrer URL),',
          itemFive:
            'browser used and, if applicable, the operating system of your computer as well as the name of your access provider.'
        },
        copyThree:
          'The above data is processed by us for the following purposes:',
        listTwo: {
          itemOne: 'Ensuring a smooth connection setup of the website,',
          itemTwo: 'Ensuring a comfortable use of our website,',
          itemThree: 'Evaluation of system security and stability as well as',
          itemFour: 'for other administrative purposes.'
        },
        copyFour:
          'The legal basis for the data processing is Art. 6 para. 1 p. 1 lit. f DSGVO. Our legitimate interest follows from the purposes for data collection listed above. In no case do we use the collected data for the purpose of drawing conclusions about your person.',
        copyFive:
          'In addition, we use cookies and analysis services when you visit our website. You can find more detailed explanations of this under sections 4 and 5 of this data protection information.'
      }
    },
    sectionThree: {
      title: '3. Newsletter registration and sending of content',
      copyOne:
        'When you register for our newsletter, we collect the personal data you provide in the registration form (e.g. name and email address). This data is processed on the basis of your consent in accordance with Art. 6 para. 1 sentence 1 lit. a GDPR. The purpose of the processing is to send you our newsletter with current information and, if applicable, additional content, such as exclusive studies, white papers or other materials.',
      copyTwo:
        'You can revoke your consent to the processing of your personal data at any time with effect for the future by using the unsubscribe link in the newsletter or by sending us an email to <a class="link link--primary link--has-underline" href="mailto:info@lingner.com">info@lingner.com</a>. The data will be deleted after you withdraw your consent, provided there are no statutory retention obligations to the contrary.'
    },
    sectionFour: {
      title: '4. Transfer of data',
      copyOne:
        'We do not transfer your personal data to third parties for purposes other than those listed below.',
      copyTwo: 'We only pass on your personal data to third parties if:',
      listOne: {
        itemOne:
          'you have given your explicit consent to do so in accordance with Art. 6 (1) p. 1 lit. a DSGVO,',
        itemTwo:
          'the disclosure is required under Art. 6 (1) p. 1 lit. f DSGVO for the assertion, execution or defense of legal claims and there is no reason to believe that you have a legitimate interest in not disclosing your data,',
        itemThree:
          'there is a legal obligation for disclosure according to Art. 6 (1) p. 1 lit. c DSGVO, as well as',
        itemFour:
          'if it is legally permissible and necessary according to Art. 6 para. 1 p. 1 lit. B DSGVO for the processing of contractual relationships with you.'
      }
    },
    sectionFive: {
      title: '5. Contact form',
      copyOne:
        'If you contact us via a contact form provided on our website, we will process the data you provide (e.g. name, email address and inquiry content) in order to process your inquiry. The processing takes place on the basis of your consent in accordance with Art. 6 para. 1 sentence 1 lit. a GDPR.',
      copyTwo:
        'Your data will only be used to process your request and any follow-up questions and will be deleted after the contact has ended, provided there are no statutory retention obligations. Ihre Einwilligung können Sie jederzeit mit Wirkung für die Zukunft widerrufen, indem Sie uns eine E-Mail an <a class="link link--primary link--has-underline" href="mailto:info@lingner.com">info@lingner.com</a> senden.'
    },
    sectionSix: {
      title: '6. Cookies',
      copyOne:
        'We use cookies on our website. These are small files that are automatically created by your browser and stored on your end device (laptop, tablet, smartphone or similar) when you visit our site. Cookies do not cause any damage to your end device, do not contain viruses, Trojans or other malware. In the cookie, information is stored that arises in connection in each case with the specific end device. This does not mean that we gain direct knowledge of your identity using cookies.',
      copyTwo:
        'The use of cookies is intended to make the use of our website more pleasant for you. For example, we use so-called session cookies to recognize that you have already visited individual pages of our website. These are automatically deleted after you leave our website. In addition, we also use temporary cookies to optimize user-friendliness, which are stored on your terminal device for a fixed period. If you visit our website again, we can automatically recognize that you have already been to our site and which entries and settings you have made so that you do not have to enter them again. The data processed by cookies is necessary for the mentioned purposes to protect our legitimate interests and those of third parties in accordance with Art. 6 (1) p. 1 lit. f DSGVO.',
      copyThree:
        'We also use cookies to statistically record the use of our website and to evaluate it for the purpose of optimizing our offer for you (see section 5). These cookies enable us to automatically recognize that you have already been to our website when you visit it again. These cookies are automatically deleted after a specified period. The data processed by these cookies is processed for the mentioned purposes only with your agreement in accordance with Art. 6 (1) p. 1 lit. a DSGVO.',
      copyFour:
        'Lingner allows you to control the use of cookies. To do so, click on the "Cookie settings" link in the footer. Here you can set which cookies you want to use at any time. We categorize cookies as follows:',
      copyFive:
        '<em>Necessary cookies (level 1)</em><br>These cookies are mandatory for our websites and their functions to work properly, e.g., saving your cookie settings.',
      copySix:
        '<em>User preference cookies (level 2)</em><br>Preference cookies enables the website to remember information to customize how the web site looks or behaves for each user.',
      copySeven:
        '<em>Analytical cookies (level 3)</em><br>These cookies collect information about how you use our websites.',
      copyEight:
        'Most browsers accept cookies automatically. You can reconfigure your browser so that no cookies are stored on your device, or a message always appears before a new cookie is created. Complete deactivation of cookies may, however, result in you not being able to use all the functions of our website.'
    },
    sectionSeven: {
      title: '7. Tracking tools',
      copyOne:
        'The tracking measures listed below are carried out based on Art. 6 (1) p. 1 lit. a DSGVO. With the tracking measures we want to ensure a customized design and continuous optimization of our website. On the other hand, we use tracking measures to statistically record the use of our website and evaluate it for the purpose of optimizing our offer for you. These interests are to be regarded as legitimate within the meaning of the aforementioned provision. The respective data processing purposes and data categories can be found in the corresponding tracking tools.',
      subparagraphA: {
        title: 'a) Google Analytics',
        copyOne:
          'For the purpose of demand-driven design and ongoing optimization of our pages, we use Google Analytics, a web analytics service provided by Google Inc (https://about.google/intl/en-GB/) (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; hereinafter "Google"). In this context, pseudonymized usage profiles are created and cookies are used (see under section 4). The information generated by the cookie about your use of this website such as',
        listOne: {
          itemOne: 'Browser type/version,',
          itemTwo: 'operating system used,',
          itemThree: 'Referrer URL (the previous visited page),',
          itemFour: 'host name of the accessing computer (IP address),',
          itemFive: 'time of the server request,'
        },
        copyTwo:
          'are transmitted to a Google server in the USA and stored there. The information is used to evaluate the use of the website, to compile reports on website activity and to provide other services related to website and internet use for the purposes of market research and demand-oriented design of these web pages. This information may also be transferred to third parties if this is required by law or if third parties process this data on our behalf. Under no circumstances will your IP address be merged with other Google data. The IP addresses are anonymized so that an assignment is not possible (IP masking). You can refuse the use of cookies by selecting the appropriate settings on your browser, but you may however not be able to use the full functionality of this website if you do so.',
        coptThree:
          'You can also prevent the collection of data generated by the cookie and related to your use of the website (including your IP address) and the processing of this data by Google by downloading and installing a browser add-on (https://tools.google.com/dlpage/gaoptout?hl=en).',
        copyFour:
          'Further information on data protection in connection with Google Analytics can be found, for example, in the Google Analytics help (https://support.google.com/analytics/answer/6004245?hl=en).'
      },
      subparagraphB: {
        title: 'b) Google Tag Manager',
        copyOne:
          'We use the Google Tag Manager on our website. The Google Tag Manager is a service of Google Ireland Limited ("Google"), Gordon House, Barrow Street, Dublin 4, Ireland.',
        copyTwo:
          'The Google Tag Manager allows us to integrate various codes and services on our website in an orderly and simplified manner. The Google Tag Manager implements the tags or "triggers" the embedded tags. When a tag is triggered, Google may process information (including personal data) and process it. It cannot be ruled out that, thereby, Google also transmits the information to a server in a third country.',
        copyThree:
          'In particular, the following personal data is processed by the Google Tag Manager:',
        listOne: {
          itemOne: 'Online identifiers (including cookie identifiers)',
          itemTwo: 'IP address'
        },
        copyFour:
          'In addition, you can find more detailed information about the Google Tag Manager on the websites https://www.google.com/tagmanager/use-policy.html and at https://policies.google.com/?hl=en under the section "Data we receive based on your use of our services".',
        copyFive:
          'Furthermore, we have concluded a data processing contract with Google for the use of the Google Tag Manager (Art. 28 DSGVO). Google processes the data on our behalf in order to trigger the stored tags and display the services on our website. Google may transfer this information to third parties if required by law or if third parties process this data on behalf of Google.',
        copySix:
          'If you have deactivated individual tracking services (e.g. by setting an opt-out cookie), the deactivation remains in effect for all affected tracking tags that are integrated by the Google Tag Manager.',
        copySeven:
          'By integrating the Google Tag Manager, we pursue the purpose of being able to carry out a simplified and clear integration of various services. In addition, the integration of the Google Tag Manager optimizes the loading times of the various services.',
        copyEight:
          'The legal basis for the processing of personal data described here as part of the measurement process is your express consent pursuant to Art. 6 (1) a) DSGVO.'
      },
      subparagraphC: {
        title: 'c) Google Adwords Conversion Tracking',
        copyOne: `In order to statistically record the use of our website and to evaluate it for the purpose of optimizing our website for you, we also use Google Conversion Tracking. In this process, Google Adwords sets a cookie (see section 4) on your computer if you have accessed our website via a Google ad. These cookies expire after 30 days and are not used for personal identification. If the user visits certain pages of the Adwords customer's website and the cookie has not yet expired, Google and the customer can recognize that the user clicked on the ad and was redirected to this page. Each Adwords customer receives a different cookie. Cookies can hence not be tracked across Adwords customers' websites. The information obtained by using the conversion cookie is used to create conversion statistics for Adwords customers who have opted for conversion tracking. The Adwords customers learn the total number of users who clicked on their ad and were redirected to a page tagged with a conversion tracking tag. However, they do not receive any information that can be used to personally identify users.`,
        copyTwo:
          'If you do not wish to participate in the tracking process, you can also refuse the setting of a cookie required for this - for example, via a browser setting that deactivates the automatic setting of cookies. You can also disable cookies for conversion tracking by setting your browser to block cookies from the "www.googleadservices.com" domain.',
        copyThree: `Google's privacy policy on conversion tracking can be found here (https://services.google.com/sitestats/en.html).`
      }
    },
    sectionEight: {
      title: '8. Social media plug-ins',
      copyOne:
        'We use social plug-ins of the social networks Youtube and Vimeo on our website based on Art. 6 (1) p. 1 lit. a DSGVO in order to make our agency more popular. The promotional purpose is to be regarded as a legitimate interest within the meaning of the DSGVO. The responsibility for data protection-compliant operation is to be ensured by their respective providers. These plug-ins are integrated through the so-called two-click method in order to protect visitors to our website as best as possible.',
      subparagraphA: {
        title: 'a) YouTube',
        copyOne: `Plug-ins of the video streaming service of YouTube LLC (Youtube) are integrated on our website. You can recognize the Youtube plugins (video embed) by the Youtube logo on our site. When you access a page of our website that contains this kind of plugin, a direct connection is established between your browser and the Youtube server. Youtube will thereby receive the information that you have visited our site with your IP address. If you click the Youtube "play button" while logged into your Youtube account, you can link the content of our pages on your Youtube profile. This allows Youtube to associate the visit of our pages with your user account. We would like to point out that we, as the provider of the pages, have no knowledge of the content of the transmitted data or its use by Youtube. If you do not want Youtube to be able to associate the visit to our pages, please log out of your Youtube user account. For more information, please see Youtube's privacy policy (https://policies.google.com/privacy?hl=en).`
      },
      subparagraphB: {
        title: 'b) Vimeo',
        copyOne:
          'Plugins of the video streaming service of Vimeo Inc (Vimeo) are integrated on our website. You can recognize the Vimeo plugins (video embed) by the Vimeo logo on our site. When you access a page of our website that contains this kind of plugin, a direct connection is established between your browser and the Vimeo server. Vimeo thereby receives the information that you have visited our site with your IP address. If you click the Vimeo "play button" while logged into your Vimeo account, you can link the content of our pages on your Vimeo profile. This allows Vimeo to associate the visit of our pages with your user account. We would like to point out that we, as the provider of the pages, have no knowledge of the content of the transmitted data or its use by Vimeo. If you do not want Vimeo to be able to associate your visit to our pages, please log out of your Vimeo user account. For more information, please see the Vimeo privacy policy (https://vimeo.com/privacy).'
      },
      subparagraphC: {
        title: 'c) Facebook',
        copyOne:
          'We have integrated elements of the social network Facebook on this website. The provider of this service is Meta Platforms Ireland Limited, 4 Grand Canal Square, Dublin 2, Ireland. According to Facebook’s statement the collected data will be transferred to the USA and other third-party countries too.',
        copyTwo:
          'An overview of the Facebook social media elements is available under the following link:<br>https://developers.facebook.com/docs/plugins/.',
        copyThree:
          'If the social media element has been activated, a direct connection between your device and the Facebook server will be established. As a result, Facebook will receive information confirming your visit to this website with your IP address. If you click on the Facebook Like button while you are logged into your Facebook account, you can link content of this website to your Facebook profile. Consequently, Facebook will be able to allocate your visit to this website to your user account. We have to emphasize that we as the provider of the website do not receive any information on the content of the transferred data and its use by Facebook. For more information, please consult the Data Privacy Policy of Facebook at:<br>https://de-de.facebook.com/privacy/explanation.',
        copyFour:
          'If your approval (consent) has been obtained the use of the abovementioned service shall occur on the basis of Art. 6 Sect. 1 lit. a GDPR and § 25 TTDSG (German Telecommunications Act). Such consent may be revoked at any time. If your consent was not obtained, the use of the service will occur on the basis of our legitimate interest in making our information as comprehensively visible as possible on social media.',
        copyFive:
          'Insofar as personal data is collected on our website with the help of the tool described here and forwarded to Facebook, we and Meta Platforms Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Ireland are jointly responsible for this data processing (Art. 26 DSGVO). The joint responsibility is limited exclusively to the collection of the data and its forwarding to Facebook. The processing by Facebook that takes place after the onward transfer is not part of the joint responsibility. The obligations incumbent on us jointly have been set out in a joint processing agreement. The wording of the agreement can be found under:<br>https://www.facebook.com/legal/controller_addendum. According to this agreement, we are responsible for providing the privacy information when using the Facebook tool and for the privacy-secure implementation of the tool on our website. Facebook is responsible for the data security of Facebook products. You can assert data subject rights (e.g., requests for information) regarding data processed by Facebook directly with Facebook. If you assert the data subject rights with us, we are obliged to forward them to Facebook.',
        copySix:
          'Data transmission to the US is based on the Standard Contractual Clauses (SCC) of the European Commission. Details can be found here:<br>https://www.facebook.com/legal/EU_data_transfer_addendum,<br>https://de-de.facebook.com/help/566994660333381 and<br>https://www.facebook.com/policy.php.'
      },
      subparagraphD: {
        title: 'd) LinkedIn',
        copyOne:
          'This website uses elements of the LinkedIn network. The provider is LinkedIn Ireland Unlimited Company, Wilton Plaza, Wilton Place, Dublin 2, Ireland.',
        copyTwo:
          'Any time you access a page of this website that contains elements of LinkedIn, a connection to LinkedIn’s servers is established. LinkedIn is notified that you have visited this website with your IP address. If you click on LinkedIn’s “Recommend” button and are logged into your LinkedIn account at the time, LinkedIn will be in a position to allocate your visit to this website to your user account. We have to point out that we as the provider of the websites do not have any knowledge of the content of the transferred data and its use by LinkedIn.',
        copyThree:
          'If your approval (consent) has been obtained the use of the abovementioned service shall occur on the basis of Art. 6 (1)(a) GDPR and § 25 TTDSG (German Telecommunications Act). Such consent may be revoked at any time. If your consent was not obtained, the use of the service will occur on the basis of our legitimate interest in making our information as comprehensively visible as possible on social media.',
        copyFour:
          'Data transmission to the US is based on the Standard Contractual Clauses (SCC) of the European Commission. Details can be found here:<br>https://www.linkedin.com/help/linkedin/answer/62538/datenubertragung-aus-der-eu-dem-ewr-und-derschweiz?lang=en.',
        copyFive:
          'For further information on this subject, please consult LinkedIn’s Data Privacy Declaration at:<br>https://www.linkedin.com/legal/privacy-policy.'
      }
    },
    sectionNine: {
      title: '9. Data subject rights',
      copyOne: 'You have the rights:',
      listOne: {
        itemOne:
          'pursuant to Art. 15 DSGVO, to request information about your personal data processed by us. In particular, you can request information about the processing purposes, the category of personal data, the categories of recipients to whom your data has been or will be disclosed, the planned storage period, the existence of a right to rectification, erasure, restriction of processing or objection, the existence of a right of complaint, the origin of your data if it has not been collected by us, and the existence of automated decision-making, including profiling, and, if applicable, meaningful information about its details;',
        itemTwo:
          'pursuant to Art. 16 DSGVO, to request the correction of incorrect or incomplete personal data stored by us without undue delay;',
        itemThree:
          'pursuant to Art. 17 DSGVO, to request the erasure of your personal data stored by us, unless the processing is necessary for the exercise of the right to freedom of expression and information, for compliance with a legal obligation, for reasons of public interest or for the establishment, exercise or defense of legal claims;',
        itemFour:
          'pursuant to Art. 18 DSGVO, to request the restriction of the processing of your personal data, insofar as the accuracy of the data is disputed by you, the processing is unlawful, but you object to its erasure and we no longer require the data, but you need it for the assertion, exercise or defense of legal claims or you have objected to the processing pursuant to Art. 21 DSGVO;',
        itemFive:
          'pursuant to Art. 20 DSGVO, to receive your personal data that you have provided to us in a structured, common and machine-readable format or to request that it be transferred to another controller;',
        itemSix:
          'in accordance with Art. 7 (3) DSGVO, to revoke your consent once given to us at any time. This has the consequence that we may no longer continue the data processing, which was based on this consent, for the future; and',
        itemSeven:
          'complain to a supervisory authority in accordance with Art. 77 DSGVO. Usually, you can contact the supervisory authority of your usual place of residence or workplace or our registered office for this purpose.'
      }
    },
    sectionTen: {
      title: '10. Right of objection',
      copyOne:
        'If your personal data is processed on the basis of legitimate interests pursuant to Art. 6 (1) p. 1 lit. f DSGVO, you have the right to object to the processing of your personal data pursuant to Art. 21 DSGVO, provided that there are reasons for doing so that arise from your particular situation or the objection is directed against direct advertising. In the latter case, you have a general right of objection, which is implemented by us without specifying a particular situation.',
      copyTwo:
        'If you would like to exercise your right of revocation or objection, it is sufficient to send an e-mail to <a class="link link--primary link--has-underline" href="mailto:info@lingner.com">info@lingner.com</a>.'
    },
    sectionEleven: {
      title: '11. Data security',
      copyOne:
        'Within the website visit, we use the widespread SSL procedure (Secure Socket Layer) in conjunction with the highest encryption level supported by your browser. As a rule, this is a 256-bit encryption. If your browser does not support 256-bit encryption, we use 128-bit v3 technology instead. You can tell whether an individual page of our website is encrypted by the closed key or lock symbol in the lower status bar of your browser. We also use appropriate technical and organizational security measures to protect your data against accidental or intentional manipulation, partial or complete loss, destruction or against unauthorized access by third parties. Our security measures are continuously improved in line with technological developments.'
    },
    sectionTwelve: {
      title: '12. Timeliness and changes of this data privacy policy',
      copyOne:
        'This data protection declaration is currently valid and has the status 2024. Due to the further development of our website and offers on it or due to changed legal or official requirements, it may become necessary to change this data protection declaration. You can access and print out the current data protection declaration at any time on the website at www.lingner.com/datenschutz.'
    }
  },
  exampleCase: {
    externalCommunication: {
      contactBox: {
        title: `Curious?<br>Let's talk.`
      },
      outlinks: {
        linkTwo: {
          linkText: 'New Software AG Intranet based on SharePoint',
          linkUrl: '/en/software-ag-intranet-reimagined/',
          title: 'Next case'
        },
        title: 'You might also be interested in'
      },
      sectionOne: {
        copyOne:
          'In the end, B2B communication convinces people, not machines. Therefore, even a very technical brand must strike a chord, arouse emotions, add value, and communicate competently.',
        copyTwo:
          'But how do you convince a hesitant target group? How do you stage the market launch of a new product? Together with our client Dentsply Sirona, we faced precisely these challenges. In the end, we created an exceptionally convincing and well orchestrated B2B campaign, which we implemented across channels and in a goal-oriented manner. The campaign film was distributed through an extensive campaign with a focus on social media and supplemented by print advertisements, as well as online and offline events. With this, we achieved a greater relevance for the brand and real support for sales.',
        title: 'B2B campaigns & digital marketing'
      },
      sectionTwo: {
        copyOne:
          'If a large target group needs to be reached with important content, we design the right format and set the right stage. Whether live, digital, or on site. Just like for the German Federal Ministry of Health, when it was said "Together against Corona". It was our job to spread this message among the people. For this purpose, we developed a streaming concept and then implemented it live in Berlin. The streams with the then Health Minister Jens Spahn reached millions of viewers across all major TV media and social media channels.',
        copyTwo: `However, target groups can also be very specific, as with Interpharm Online. For three days, all pharmacists had to be reached with educational content and top speakers. We designed the entire online event and implemented it on site. The enthusiasm was huge and the added value guaranteed. Above all, products that require explanation and are difficult to transport, or those that are still in the development phase, benefit from a "digital twin". With the help of a digital image of the product, it's not only possible to merge the digital and the real world, but also to show processes that are not visible to the naked eye in the real world.`,
        title: 'Live communication'
      },
      sectionThree: {
        copyOne:
          'Not just people, but also products and solutions need a (live) stage. Because in the end, we as people prefer to source our products from brands that we know and trust. With the Solid Wood Summit, we developed a unique digital trade show format and our own digital platform for the world market leader Weinig AG (machines and systems for solid wood processing). For three days, panel discussions, keynote speeches and expert interviews around the globe could be streamed live. A massive innovation for the entire industry.',
        copyTwo:
          'With our client Warema, we also succeeded in setting new standards in terms of dialogue, further training and inspiration with the trend congress for the sun protection industry. Together, we developed the new event format - and the plan worked: Around 120 specialist partners put together their own programme from the wide range of products and services on offer, and at the same time took the opportunity to discuss the current industry topics. We accompanied the entire event from conception to technical implementation.',
        copyThree:
          'Live events particularly benefit from the technology of digital twins. This virtual image makes it possible to visualise complex relationships or processes in live events in an eye-catching and easily understandable way.',
        title: 'Live sales'
      },
      sectionFour: {
        copyOne: `On a global level, it's considered a real success booster to create company-wide standards for social media communication, to take advantages of synergies and to make successes measurable. Issues such as sender structure, guidelines, and reaction processes are just a few of the relevant points in a global strategy process.`,
        copyTwo:
          'Our experience from working with companies such as QIAGEN, RENOLIT, or Kühne & Nagel shows that processes and standards must be defined in international exchange with those responsible. The goal is to empower the communicators. For this purpose, a clear line with a lot of leeway for the target group-specific dialogue will be developed.',
        copyThree: `Let's also work together to shape your global social media landscape. You can find out what this can look like from Qiagen. Click here for the podcast with Dr. Thomas Theuringer, Senior Director Corporate Communications & Head of External Communications.`,
        copyFour: `Whether we're speaking of employee advocacy, corporate influencers or employees as brand ambassadors, the message remains the same: In B2B, employees are also the communicators of tomorrow. This applies to managers as well as interns.`,
        copyFive:
          'We accompany you on your way to the Corporate Influencer Program and place your executives on social media. We establish new personal brands and position the thought leaders of your industry.',
        subTitleOne: 'Social media global thinking (social media empowerment)',
        subTitleTwo: 'Next level',
        title: 'Social media strategies'
      },
      sectionFive: {
        copyOne: `Digital disruption has made the network one of the largest sales markets for many companies and one of the most important communication platforms in their portfolio - and if it isn't already, it will be in the near future. Because people want one thing above all else: exchange. The corporate website is the first digital touch point with which a company presents itself to interested parties, potential clients or new talents. A corporate website is now much more than a multifunctional interaction and communication platform. Increasingly digital customer journeys for real differentiation and positioning in the market are the success factor for this.`,
        copyTwo:
          'We design your corporate website taking into account the overall (digital) strategy and thus create a holistic, seamless brand experience - both off and online. Our team of developers implements the designed website with a suitable technology for the project.',
        title: 'Corporate websites'
      },
      sectionSix: {
        copyOne: `"B2B has its own rules." With exactly this sentence, B2B brands have fought against better knowledge for decades. Whether manufacturers of machines, dealers of outer packaging, developers of medical technology or agricultural products, they all realise that their product alone is no longer enough to make big leaps on the business-to-business market.`,
        copyTwo: `With digitization, many of our clients are also growing into new markets. In recent years, for example, Bilfinger has changed from a construction company to an international engineering and service group. In order to increase the group's public perception and underline Bilfinger's wide range of services, the company is now relying on a uniform brand. Here we revised corporate design and thus created a strong uniform brand with high recognition value across all digital touch points.`,
        title: 'Brand & Strategy'
      }
    },
    internalCommunication: {
      contactBox: {
        title: `Curious?<br>Let's talk.`
      },
      outlinks: {
        linkTwo: {
          linkText: 'New Software AG Intranet based on SharePoint',
          linkUrl: '/en/software-ag-intranet-reimagined/',
          title: 'Next case '
        },
        title: 'You might also be interested in'
      },
      sectionZero: {
        copyOne:
          'Internal communication binds employees emotionally to the company. For us, this means developing and implementing content and formats that offer employees real added value.',
        copyTwo:
          'There are numerous sources of exciting content within companies, but also the need to communicate important messages. Attractive internal communication shapes the corporate culture and sharpens the brand tone of the company.'
      },
      sectionOne: {
        copyOne:
          'How do you get employees to consume corporate content with pleasure and gain more information than in the classic corporate newsletter?',
        copyTwo:
          'The video magazine we developed, Dentsply Sirona News - an intranet TV format - informs and is entertaining at the same time. It is published quarterly in a length of about 10 to 15 minutes. The presenter leads through the program and introduces the various articles. These include current announcements, results of an employee survey, and much more. Other clips are part of a series and feature special achievements by employees or present content from other Dentsply Sirona locations, which promotes a sense of community within the global group.',
        copyThree:
          'Internal communication binds employees emotionally to the company. For us, this means developing and implementing content and formats that offer employees real added value.',
        copyFour:
          'There are numerous sources of exciting content within companies and also the need to communicate important messages. Attractive internal communication shapes the corporate culture and sharpens the brand tone of the company.',
        label: 'Internal video magazine',
        title: 'Emotional content with added value'
      },
      sectionTwo: {
        copyOne:
          'In particular, if the CEO, the Board of Directors or the management want to communicate pioneering information to the employees, town halls are an opportunity to send a message personally and to be open to questions at the same time. This openness has a very positive effect on critical issues. Town halls are multimedial and can be broadcast and recorded worldwide via live stream.',
        label: 'Town Hall Meetings',
        title: 'The management informs personally<br>and in real time'
      },
      sectionThree: {
        copyOne: `It's not always possible to hold a town hall, and often topics are not "big enough". Especially with shorter messages, two formats are suitable to convey the core of the content personally: video messages and interviews.`,
        copyTwo: `The interview: Here, there's deliberately no direct talk with a view into the camera. The messages are conveyed in a conversation that seems free and natural.`,
        copyThree:
          'In our productions, our editors, who are experienced in dealing with executives, ensure the quality of the content and create a pleasant atmosphere with the production team.',
        copyAsideOne:
          'The direct view to the audience - possibly with the help of a teleprompter - helps in the communication of clear, predefined content.',
        label: 'Video messages and interviews',
        title: 'Fast and authentic information transfer'
      },
      sectionFour: {
        copyOne: `Both an old hat. But few trends have grown as sustainably and strongly as podcast consumption. And while podcast use (at least once a month) in Germany (22%) has multiplied in recent years and successful formats are going on tour offline, lags in an international comparison: Swedes (36%), Spaniards (40%), or South Koreans (58%) show us that there's still plenty of room for growth.`,
        copyTwo:
          'A wide variety of podcast formats can contribute to a wide variety of communication destinations. Sounds strategic? That’s where we come in. Where when and what effect can a podcast have on people? We find the right podcast format and answer all the questions that lead to publishing a podcast that does not serve the purpose of simply riding on the wave of the podcast trend.',
        label: 'Podcasts',
        title: 'Content is king and streaming is in'
      },
      sectionFive: {
        copyOne:
          'Do you want to provide a new intranet for your employees as quickly as possible? Our white label solution is based on the open source CMS TYPO3 and can be optimally adapted to your needs and specifications. The solution offers a range of functions that meets the requirements of large corporations and their employees. A personalised intranet solution as the basis for the digital workplace.',
        label: 'White label intranet',
        listItemOne: 'Basic intranet functional in 4 weeks',
        listItemTwo: 'No licensing costs',
        listItemThree: 'Fully customisable',
        listItemFour:
          'Interfaces to document management systems and employee databases',
        title: 'From 0 to intranet in 4 weeks'
      },
      sectionSix: {
        copyOne:
          'Make your own successes visible and present them authentically. Your own employees are perfectly suited for this purpose and present their personal success stories to their colleagues or direct their attention to innovations.',
        copyTwo:
          'Not only the communication of information is emphasised, but also the appreciation and expert knowledge of the protagonists.',
        label: 'By employees for employees',
        title: 'Info videos and success stories'
      },
      sectionSeven: {
        copyOne:
          'With the LINGNER.WERK2, our business event location in Heilbronn, we offer you an alternative location to your company or a studio for filming, but above all as a space for workshops and events. More information can be found on our website: <a class="link link--secondary" href="https://werk2.lingner.com/" target="_blank">werk2.lingner.com</a>.',
        label: 'Business event location in Heilbronn',
        title: 'LINGNER.WERK2'
      }
    }
  },
  header: {
    close: 'Close',
    languageNotification:
      'This page is displayed in english. In case, you would rather read in german, you could switch to',
    languageNotificationLink: 'german',
    menu: 'Menu'
  },
  homePage: {
    boutiqueTeaserOne: {
      cardCopy: 'TODO',
      copy: 'We guide companies from the first "I can imagine that" to the final KPI evaluation. To do that, we work with the best in both in-house and remote settings. Be it on site, in the network, in the process, in the organization, in the code, in the ear, on set, or in the feed. No matter where. The main thing is that we are relevant and memorable.',
      title: 'The digital B2B boutique agency. '
    },
    caseSudiesTeaser: {
      title: 'Case studies and insights.',
      exampleCasesTitle: 'Discover even more:'
    },
    contactBox: {
      title: 'We are looking forward to your call!'
    },
    customerLogos: {
      title: 'Working for industry, SMEs and B2B. For over 20 years.'
    },
    mainTeaserOne: {
      copyOne:
        'Of course, this love is sometimes complicated. But this doesn’t prevent us from conceiving of and implementing our great love of B2B communication. On the contrary, the more complex the topic, the more our digital heart flutters. We love to turn complex topics into fascinating and accessible communication. And this has been the case since 1996. Our clients are global B2B market leaders and their products not only need to be explained, but also resonate with a demanding and international specialist audience.',
      title: 'For us,<br>B2B is just another word for love.'
    },
    mainTeaserTwo: {
      copyOne:
        'Over 20 years ago, <a id="stefanLingner" class="link link--primary link--has-underline" data-modal-type="content" data-modal-data="stefanLingner">Stefan Lingner</a> founded this agency with a clear vision. Under joint management with <a id="tobiasHurrle" class="link link--primary link--has-underline" data-modal-type="content" data-modal-data="tobiasHurrle">Tobias Hurrle</a>, that vision of an exclusively B2B boutique agency is constantly evolving.',
      copyTwo:
        'Partners <a id="fabianBorst" class="link link--primary link--has-underline" data-modal-type="content" data-modal-data="fabianBorst">Fabian Borst</a>, <a id="christianLaurin" class="link link--primary link--has-underline" data-modal-type="content" data-modal-data="christianLaurin">Christian Laurin</a>, <a id="wojtekMisiewicz" class="link link--primary link--has-underline" data-modal-type="content" data-modal-data="wojtekMisiewicz">Wojtek Misiewicz</a>, and <a id="danielNill" class="link link--primary link--has-underline" data-modal-type="content" data-modal-data="danielNill">Daniel Nill</a> added additional facets such as social media, video, branding, campaigning, web development, digital platform conception, and the art of the latte.',
      title: 'B2B.<br>Out of passion.<br>And principle.'
    },
    mainTeaserThree: {
      title: 'Become lingner.com',
      copyOne:
        'Our communication is constantly changing, which is why we’re always looking for creative, wonderful, changeable and versatile professionals.',
      copyTwo:
        'Do you want to spin crazy ideas with us, discuss them, change them around, and finally realize them? Not necessarily in that order, by the way. Then have a look.',
      cta: 'Work at lingner.com'
    },
    newsTeaser: {
      title: `What's new at lingner.com`,
      cta: 'News Archive'
    },
    podularTeaserOne: {
      itemOneCopy:
        'Podular is the merging of Pod and Modular. Our experts form teams (pods) from all communicative disciplines of a full-service agency. We always combine these individually (modularly) to master every communicative challenge. In this way, we remain collaborative, dynamic and capable of action, without building unnecessarily large teams or creating artificial hierarchies.',
      itemOneTitle: 'Podular mode of operation',
      itemTwoCopy:
        'What you see is what you get. This applies to us and especially in our teams, because as a boutique agency, we work exclusively with experts at a high level – both in-house and in the network. For more impact.',
      itemTwoTitle: 'Specialist teams at senior level',
      itemThreeCopy: `We don't just want to work on or for a client. We really want to make a difference. And that rarely happens by just checking off marketing lists. We can't do that either by retreating to our ivory tower after the briefing until white smoke rises and we descend with the holy communication concept. Rather, we want to work with our clients to determine what we really want to achieve before we decide how big the banner can be.`,
      itemThreeTitle: 'Co-creation processes',
      title: 'What are the advantages of a boutique agency?'
    }
  },
  impressionsSection: {
    title: 'Impressions from lingner.com',
    copyOne: ' ',
    copyTwo: ' ',
    copyThree: ' ',
    copyFour: ' ',
    copyFive: ' '
  },
  footer: {
    backToTop: 'Back to top'
  },
  global: {
    case: 'Case',
    news: 'News',
    pages: {
      agb: {
        title: 'AGB'
      },
      career: {
        title: 'Career'
      },
      caseStudy: {
        dentsplySironaPrimemillProductLaunch: {
          title: 'Cerec Primemill Product Launch'
        }
      },
      contact: {
        title: 'Contact'
      },
      dataProtection: {
        title: 'Data Protection'
      },
      home: {
        titleOne: 'About lingner.com',
        titleTwo: 'Home',
        titleThree: 'lingner.com'
      },
      imprint: {
        title: 'Imprint'
      },
      news: {
        titleOne: 'News',
        titleTwo: 'News Archive'
      },
      sitemap: {
        title: 'Sitemap'
      }
    },
    readNow: 'Read now',
    workAtLingner: 'Working at lingner.com'
  },
  imprintPage: {
    property: {
      title: 'Information according to § 5 TMG:',
      copyOne: 'lingner.com is the homepage of'
    },
    company: {
      title: 'LINGNER CONSULTING NEW MEDIA GMBH',
      address: 'Rosenberg 1<br>74072 Heilbronn'
    },
    contacts: {
      title: 'LOCATION JAGSTHAUSEN',
      address: 'Im Bannholz 2<br>74249 Jagsthausen',
      phoneFax:
        'Telephone: 0 71 31 / 642 90 30<br>Fax: 0 79 43 / 94 27-70<br>info@lingner.com'
    },
    directors: {
      title: 'Managing Director',
      copyOne: 'Stefan Lingner // Tobias Hurrle',
      subTitle: 'Registered office of the company:',
      copyTwo: 'Jagsthausen // Heilbronn',
      copyThree: 'HRB 740189, Stuttgart Local Court<br>USt-IdNr. DE282050365'
    },
    legalNotice: {
      title: 'Legal notice',
      copyOne:
        '&copy;	Copyright LINGNER CONSULTING NEW MEDIA GMBH<br>All rights reserved. Reprinting, inclusion in online services, Internet and duplication on data carriers is permitted with the prior consent of LINGNER.',
      copyTwo:
        'This website has been compiled with the greatest possible care. Nevertheless, we cannot guarantee the correctness and accuracy of the information contained therein. We exclude all liability for damages arising directly or indirectly from the use of this website, insofar as these are not based on intention or gross negligence.'
    },
    linksLiability: {
      title: 'Liability for links',
      copyOne:
        'Our website contains links to external websites of third parties over whose content we have no influence. Therefore, we cannot assume any liability for these external contents. The respective provider or operator of the linked pages is always responsible for the content of the pages. The linked pages were checked for possible legal violations at the time of linking. Illegal contents were not recognizable at the time of linking. However, permanent monitoring of the content of the linked pages is not reasonable without concrete indications of a legal violation. If we become aware of any infringements of the law, we will remove such links immediately.'
    },
    contentLiability: {
      title: 'Liability for contents',
      copyOne:
        'As a service provider, we are responsible for our own content on these pages in accordance with the general laws according to § 7 Para. 1 TMG. According to §§ 8 to 10 TMG, however, we are not obliged as a service provider to monitor transmitted or stored third-party information or to investigate circumstances that indicate illegal activity. Obligations to remove or block the use of information in accordance with general laws remain unaffected by this. However, liability in this regard is only possible from the point in time at which a concrete infringement of the law becomes known. If we become aware of corresponding infringements, we will remove this content immediately.'
    }
  },
  jobPage: {},
  jobCategoryPage: {
    contactBox: {
      title: 'I look forward to hear from you.'
    },
    featureBox: {
      title: `Why isn't my job listed?`,
      copyOne: `A very good question. It could be because we don't even know that we need you yet. So if you know what we need, just send us your CV by email or give us a good old-fashioned phone call.`,
      cta: 'Initiative application'
    },
    jobsList: {
      title: 'Open positions'
    },
    bottomCta: 'More about lingner.com'
  },
  navigationMenu: {
    blockOne: {
      copyOne: `Here you can find out (almost) everything there is to know about our agency.`,
      copyTwo:
        'For example: Why we spell love with <a id="scroll-to-wir-schreiben-liebe-mit-zwei-b-b2b" class="link link--tertiary link--has-underline scroll-to" href="/en/#wir-schreiben-liebe-mit-zwei-b-b2b">two \'B\'s</a>, the <a id="scroll-to-b2b-mit-leidenschaft-und-aus-prinzip" class="link link--tertiary link--has-underline scroll-to" href="/en/#b2b-mit-leidenschaft-und-aus-prinzip">story</a> behind our agency and the <a id="scroll-to-was-sind-die-vorteile-einer-boutique-agentur" class="link link--tertiary link--has-underline scroll-to" href="/en/#was-sind-die-vorteile-einer-boutique-agentur">advantages of being a boutique agency</a>. You can also browse our <a id="scroll-to-case-studies-and-insights" class="link link--tertiary link--has-underline scroll-to" href="/en/#case-studies-and-insights">case studies</a> and look at some <a id="scroll-to-eindruecke-von-lingnercom" class="link link--tertiary link--has-underline scroll-to" href="/en/#eindruecke-von-lingnercom">impressions</a>, see what\'s <a id="scroll-to-neues-bei-lingnercom" class="link link--tertiary link--has-underline scroll-to" href="/en/#neues-bei-lingnercom">new at lingner.com</a> or <a id="scroll-to-und-so-erreichst-du-uns" class="link link--tertiary link--has-underline scroll-to" href="/en/#und-so-erreichst-du-uns">contact us</a> directly.',
      title: 'About<br>lingner.com'
    },
    blockTwo: {
      copyOne:
        'Here you can find out everything you need to know about working with us.',
      copyTwo:
        'For example: Why yellow also means <a id="scroll-to-team-gelb" class="link link--tertiary link--has-underline scroll-to" href="/en/career#team-gelb">colourful</a> to us. Take the <a id="scroll-to-irgendwas-mit-medien" class="link link--tertiary link--has-underline scroll-to" href="/en/career#irgendwas-mit-medien">initiative</a> or find out which <a id="scroll-to-offene-stellen" class="link link--tertiary link--has-underline scroll-to" href="/en/career#offene-stellen">positions</a> are currently in demand. And if you\'re still looking for a reason to join us, we even got <a id="scroll-to-und-warum-lingner" class="link link--tertiary link--has-underline scroll-to" href="/en/career#und-warum-lingner">14 good ones</a>. Sounds cool? Then why not <a id="scroll-to-contact-box" class="link link--tertiary link--has-underline scroll-to" href="/en/career#contact-box">contact us</a> directly?',
      title: 'Working at<br>lingner.com'
    },
    close: 'Close menu'
  },

  newsPage: {
    emptyTitle: 'News about lingner.com'
  },
  notFoundPage: {
    titleOne: `Sorry, we couldn't find this page.`,
    titleTwo: 'Now go back to',
    link: 'Homepage'
  },
  team: {
    stefanLingner: {
      role: 'Managing director'
    },
    tobiasHurrle: {
      role: 'Managing director'
    },
    fabianBorst: {
      role: 'Business partner'
    },
    christianLaurin: {
      role: 'Business partner'
    },
    wojtekMisiewicz: {
      role: 'Business partner'
    },
    danielNill: {
      role: 'Business partner'
    },
    carolaRogge: {
      role: 'People & Culture'
    }
  },
  url: {
    agb: '/assets/static/lin_18_AGB_20180724.pdf',
    career: '/en/career/',
    caseStudy: {
      dentsplySironaPrimemillProductLaunch:
        '/en/dentsply-sirona-primemill-product-launch/'
    },
    dataProtection: '/en/dataprotection/',
    home: '/en/',
    imprint: '/en/imprint/',
    news: '/en/news/',
    tiktok: 'https://www.tiktok.com/@lingner.com',
    sitemap: '/en/sitemap/',
    gwa: 'https://www.gwa.de/'
  },
  sitemap: {
    heading: 'Sitemap of lingner.com',
    home: {
      pageTitle: 'Home',
      url: '/en/'
    },
    news: {
      pageTitle: 'News',
      url: '/en/news/'
    },
    imprint: {
      pageTitle: 'Imprint',
      url: '/en/imprint/'
    },
    dataProtection: {
      pageTitle: 'Data Protection',
      url: '/en/dataprotection/'
    },
    agb: {
      pageTitle: 'AGB',
      url: '/assets/static/lin_18_AGB_20180724.pdf'
    },
    career: {
      pageTitle: 'Career',
      url: '/en/career/'
    },
    careerAdministration: {
      pageTitle: 'Administration',
      url: '/en/career/administration/'
    },
    careerCreation: {
      pageTitle: 'Creation',
      url: '/en/career/creation/'
    },
    careerDevelopment: {
      pageTitle: 'Development',
      url: '/en/career/development/'
    },
    careerConsultation: {
      pageTitle: 'Consultation',
      url: '/en/career/consultation/'
    },
    careerAugmentedReality: {
      pageTitle: 'Augmented Reality',
      url: '/en/career/augmentedreality/'
    }
  }
};
